import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  Legend,
  Brush,
  ReferenceLine,
} from "recharts";
import currencyFormatter from "../utils/utils";
import IndexCurrency from "./IndexCurrency";

export default function NetLiquidityModel(props) {
  const [liquidityData, setLiquidityData] = useState(undefined);
  const [correlationValue, setCorrelationValue] = useState(undefined);
  const [dataMode, setDataMode] = useState("original");
  const [openErrorMessage, setOpenErrorMessage] = useState(false);

  const refreshData = () => {
    fetch(window.location.protocol + "//api.asxsardines.com.au/netLiquidity")
      .then((res) => res.json())
      .then((res) => {
        setLiquidityData(res);
      })
      .catch((err) => {
        setOpenErrorMessage(true);
        console.error(err);
      });

    fetch(
      window.location.protocol +
        "//api.asxsardines.com.au/netLiquidityCorrelation"
    )
      .then((res) => res.json())
      .then((res) => {
        setCorrelationValue(res);
      })
      .catch(console.error);
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <>
      {liquidityData ? (
        <div style={{ width: "100%", height: 600 }}>
          <h3 style={{ fontFamily: "'Oswald', sans-serif" }}>
            S&P 500 vs Net Liquidity Comparison. Calculated Correlation:{" "}
            {parseFloat(correlationValue).toFixed(4)} (Past 108 Weeks)
          </h3>

          <FormControl>
            <FormLabel>Data Mode</FormLabel>
            <RadioGroup
              row
              value={dataMode}
              onChange={(e) => setDataMode(e?.target?.value)}
            >
              <FormControlLabel
                value="original"
                control={<Radio />}
                label="Original"
              />
              <FormControlLabel
                value="liabilities"
                control={<Radio />}
                label="Liabilities"
              />
              <FormControlLabel
                value="liabilitiesOriginal"
                control={<Radio />}
                label="Liabilities Default"
              />
              <FormControlLabel
                value="global"
                control={<Radio />}
                label="Global"
              />
            </RadioGroup>
          </FormControl>
          <ResponsiveContainer>
            <ComposedChart
              width={1000}
              height={600}
              data={liquidityData}
              margin={{
                top: 10,
                right: 100,
                left: 50,
                bottom: 0,
              }}
              syncId="netLiquidity"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                angle={-60}
                type="number"
                tickFormatter={(value) => currencyFormatter(value)}
                domain={["dataMin - 200", "dataMax + 200"]}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  return (
                    <div
                      className="custom-tooltip"
                      style={{
                        backgroundColor: "#ffff",
                        padding: "5px",
                        border: "1px solid #cccc",
                        alignContent: "center",
                      }}
                    >
                      <label
                        style={{
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Date: </b>
                        {payload?.[0]?.payload?.date}
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#413ea0",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>S&P 500: </b>
                        {currencyFormatter(
                          parseFloat(payload?.[0]?.payload?.sP500).toFixed(2)
                        )}
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#00FF00",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Fair Value: </b>
                        {currencyFormatter(
                          parseFloat(
                            payload?.[0]?.payload?.[
                              "fairValue" +
                                (dataMode !== "original"
                                  ? dataMode === "liabilities"
                                    ? "Liabilities"
                                    : dataMode === "liabilitiesOriginal"
                                    ? "LiabilitiesOriginal"
                                    : "Global"
                                  : "")
                            ]
                          ).toFixed(2)
                        )}
                      </label>

                      <br />
                      <label
                        style={{
                          color: "#ffc658",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Net Liquidity: </b>
                        {currencyFormatter(
                          parseFloat(
                            payload?.[0]?.payload?.[
                              "netLiquidity" +
                                (dataMode !== "original"
                                  ? dataMode === "liabilities"
                                    ? "Liabilities"
                                    : dataMode === "liabilitiesOriginal"
                                    ? "LiabilitiesOriginal"
                                    : "Global"
                                  : "")
                            ]
                          ).toFixed(2)
                        )}{" "}
                        B
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#8884d8",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Reverse Repo: </b>
                        {currencyFormatter(
                          parseFloat(payload?.[0]?.payload?.repo).toFixed(2)
                        )}{" "}
                        B
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#82ca9d",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Treasury General Account: </b>
                        {currencyFormatter(
                          parseFloat(payload?.[0]?.payload?.tGA).toFixed(2)
                        )}{" "}
                        B
                      </label>
                      <br />
                    </div>
                  );
                }}
              />
              <Line
                type="monotone"
                dataKey="sP500"
                stroke="#413ea0"
                fill="#413ea0"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey={
                  "fairValue" +
                  (dataMode !== "original"
                    ? dataMode === "liabilities"
                      ? "Liabilities"
                      : dataMode === "liabilitiesOriginal"
                      ? "LiabilitiesOriginal"
                      : "Global"
                    : "")
                }
                stroke="#00FF00"
                fill="#00FF00"
                dot={false}
              />
              <Tooltip />
              <Legend
                payload={[
                  {
                    id: "tGA",
                    type: "square",
                    value: "Treasury General Accounts",
                    color: "#82ca9d",
                  },
                  {
                    id: "repo",
                    type: "square",
                    value: "Reverse Repo",
                    color: "#8884d8",
                  },
                  {
                    id: "netLiquidity",
                    type: "square",
                    value: "Net Liquidity",
                    color: "#ffc658",
                  },
                  {
                    id: "sP500",
                    type: "square",
                    value: "S&P 500",
                    color: "#413ea0",
                  },
                  {
                    id:
                      "fairValue" +
                      (dataMode !== "original"
                        ? dataMode === "liabilities"
                          ? "Liabilities"
                          : dataMode === "liabilitiesOriginal"
                          ? "LiabilitiesOriginal"
                          : "Global"
                        : ""),
                    type: "square",
                    value: "Fair Value",
                    color: "#00FF00",
                  },
                ]}
              />
              <Brush
                dataKey="date"
                startIndex={liquidityData?.length - 300}
                endIndex={liquidityData?.length - 1}
              />
            </ComposedChart>
          </ResponsiveContainer>

          <ResponsiveContainer height={250}>
            <ComposedChart
              data={liquidityData}
              margin={{
                top: 10,
                right: 100,
                left: 50,
                bottom: 0,
              }}
              syncId="netLiquidity"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                angle={-60}
                tickFormatter={(value) => currencyFormatter(value)}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  return (
                    <div
                      className="custom-tooltip"
                      style={{
                        backgroundColor: "#ffff",
                        padding: "5px",
                        border: "1px solid #cccc",
                        alignContent: "center",
                      }}
                    >
                      <label
                        style={{
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Date: </b>
                        {payload?.[0]?.payload?.date}
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#ffc658",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Fair Value Difference: </b>
                        {currencyFormatter(
                          parseFloat(
                            payload?.[0]?.payload?.[
                              "fairValueDiff" +
                                (dataMode !== "original"
                                  ? dataMode === "liabilities"
                                    ? "Liabilities"
                                    : dataMode === "liabilitiesOriginal"
                                    ? "LiabilitiesOriginal"
                                    : "Global"
                                  : "")
                            ]
                          ).toFixed(2)
                        )}
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#82ca9d",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>TGA: </b>
                        {currencyFormatter(
                          parseFloat(payload?.[0]?.payload?.tGA).toFixed(2)
                        )}{" "}
                        B
                      </label>
                    </div>
                  );
                }}
              />
              <Line
                dot={false}
                type="monotone"
                dataKey={
                  "fairValueDiff" +
                  (dataMode !== "original"
                    ? dataMode === "liabilities"
                      ? "Liabilities"
                      : dataMode === "liabilitiesOriginal"
                      ? "LiabilitiesOriginal"
                      : "Global"
                    : "")
                }
                stackId="1"
                stroke="#ffc658"
                fill="#ffc658"
              />
              <Tooltip />
              <Legend
                payload={[
                  {
                    id:
                      "fairValueDiff" +
                      (dataMode !== "original"
                        ? dataMode === "liabilities"
                          ? "Liabilities"
                          : dataMode === "liabilitiesOriginal"
                          ? "LiabilitiesOriginal"
                          : "Global"
                        : ""),
                    type: "square",
                    value: "Fair Value Difference",
                    color: "#ffc658",
                  },
                ]}
              />
              <Brush
                width={0}
                height={0}
                dataKey="date"
                startIndex={liquidityData?.length - 300}
                endIndex={liquidityData?.length - 1}
              />
              <ReferenceLine y={350} label="Sell" stroke="red" />
              <ReferenceLine y={150} label="Close" stroke="green" />
            </ComposedChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={250}>
            <ComposedChart
              data={liquidityData}
              margin={{
                top: 10,
                right: 100,
                left: 50,
                bottom: 0,
              }}
              syncId="netLiquidity"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                angle={-60}
                tickFormatter={(value) => currencyFormatter(value)}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  return (
                    <div
                      className="custom-tooltip"
                      style={{
                        backgroundColor: "#ffff",
                        padding: "5px",
                        border: "1px solid #cccc",
                        alignContent: "center",
                      }}
                    >
                      <label
                        style={{
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Date: </b>
                        {payload?.[0]?.payload?.date}
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#ffc658",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Fair Value Difference (Weekly): </b>
                        {currencyFormatter(
                          parseFloat(
                            payload?.[0]?.payload?.[
                              "fairValueDiff2" +
                                (dataMode !== "original"
                                  ? dataMode === "liabilities"
                                    ? "Liabilities"
                                    : dataMode === "liabilitiesOriginal"
                                    ? "LiabilitiesOriginal"
                                    : "Global"
                                  : "")
                            ]
                          ).toFixed(2)
                        )}
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#82ca9d",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>TGA (Weekly): </b>
                        {currencyFormatter(
                          parseFloat(payload?.[0]?.payload?.tGA2).toFixed(2)
                        )}{" "}
                        B
                      </label>
                    </div>
                  );
                }}
              />
              <Line
                dot={false}
                type="monotone"
                dataKey={
                  "fairValueDiff2" +
                  (dataMode !== "original"
                    ? dataMode === "liabilities"
                      ? "Liabilities"
                      : dataMode === "liabilitiesOriginal"
                      ? "LiabilitiesOriginal"
                      : "Global"
                    : "")
                }
                stackId="1"
                stroke="#ffc658"
                fill="#ffc658"
              />
              <Tooltip />
              <Legend
                payload={[
                  {
                    id:
                      "fairValueDiff2" +
                      (dataMode !== "original"
                        ? dataMode === "liabilities"
                          ? "Liabilities"
                          : dataMode === "liabilitiesOriginal"
                          ? "LiabilitiesOriginal"
                          : "Global"
                        : ""),
                    type: "square",
                    value: "Fair Value Difference (Weekly)",
                    color: "#ffc658",
                  },
                ]}
              />
              <Brush
                width={0}
                height={0}
                dataKey="date"
                startIndex={liquidityData?.length - 300}
                endIndex={liquidityData?.length - 1}
              />
              <ReferenceLine y={350} label="Sell" stroke="red" />
              <ReferenceLine y={150} label="Close" stroke="green" />
            </ComposedChart>
          </ResponsiveContainer>
          <ResponsiveContainer>
            <ComposedChart
              width={1000}
              height={600}
              data={liquidityData}
              margin={{
                top: 10,
                right: 100,
                left: 50,
                bottom: 0,
              }}
              syncId="netLiquidity"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                angle={-60}
                tickFormatter={(value) => currencyFormatter(value)}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  return (
                    <div
                      className="custom-tooltip"
                      style={{
                        backgroundColor: "#ffff",
                        padding: "5px",
                        border: "1px solid #cccc",
                        alignContent: "center",
                      }}
                    >
                      <label
                        style={{
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Date: </b>
                        {payload?.[0]?.payload?.date}
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#413ea0",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>S&P 500: </b>
                        {currencyFormatter(
                          parseFloat(payload?.[0]?.payload?.sP500).toFixed(2)
                        )}
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#00FF00",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Fair Value: </b>
                        {currencyFormatter(
                          parseFloat(
                            payload?.[0]?.payload?.[
                              "fairValue" +
                                (dataMode !== "original"
                                  ? dataMode === "liabilities"
                                    ? "Liabilities"
                                    : dataMode === "liabilitiesOriginal"
                                    ? "LiabilitiesOriginal"
                                    : "Global"
                                  : "")
                            ]
                          ).toFixed(2)
                        )}
                      </label>

                      <br />
                      <label
                        style={{
                          color: "#ffc658",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Net Liquidity: </b>
                        {currencyFormatter(
                          parseFloat(
                            payload?.[0]?.payload?.[
                              "netLiquidity" +
                                (dataMode !== "original"
                                  ? dataMode === "liabilities"
                                    ? "Liabilities"
                                    : dataMode === "liabilitiesOriginal"
                                    ? "LiabilitiesOriginal"
                                    : "Global"
                                  : "")
                            ]
                          ).toFixed(2)
                        )}{" "}
                        B
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#8884d8",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Reverse Repo: </b>
                        {currencyFormatter(
                          parseFloat(payload?.[0]?.payload?.repo).toFixed(2)
                        )}{" "}
                        B
                      </label>
                      <br />
                      <label
                        style={{
                          color: "#82ca9d",
                          fontSize: 12,
                          fontFamily: "'Oswald', sans-serif",
                        }}
                      >
                        <b>Treasury General Account: </b>
                        {currencyFormatter(
                          parseFloat(payload?.[0]?.payload?.tGA).toFixed(2)
                        )}{" "}
                        B
                      </label>
                      <br />
                    </div>
                  );
                }}
              />
              <Area
                type="monotone"
                dataKey="netLiquidity"
                stackId="1"
                stroke="#ffc658"
                fill="#ffc658"
              />
              <Area
                type="monotone"
                dataKey="repo"
                stackId="1"
                stroke="#8884d8"
                fill="#8884d8"
              />
              <Area
                type="monotone"
                dataKey="tGA"
                stackId="1"
                stroke="#82ca9d"
                fill="#82ca9d"
              />
              {/* b  */}
              <Tooltip />
              <Legend
                payload={[
                  {
                    id: "tGA",
                    type: "square",
                    value: "Treasury General Accounts",
                    color: "#82ca9d",
                  },
                  {
                    id: "repo",
                    type: "square",
                    value: "Reverse Repo",
                    color: "#8884d8",
                  },
                  {
                    id: "netLiquidity",
                    type: "square",
                    value: "Net Liquidity",
                    color: "#ffc658",
                  },
                  {
                    id: "sP500",
                    type: "square",
                    value: "S&P 500",
                    color: "#413ea0",
                  },
                  {
                    id:
                      "fairValue" +
                      (dataMode !== "original"
                        ? dataMode === "liabilities"
                          ? "Liabilities"
                          : dataMode === "liabilitiesOriginal"
                          ? "LiabilitiesOriginal"
                          : "Global"
                        : ""),
                    type: "square",
                    value: "Fair Value",
                    color: "#00FF00",
                  },
                ]}
              />
              <Brush
                width={0}
                height={0}
                dataKey="date"
                startIndex={liquidityData?.length - 300}
                endIndex={liquidityData?.length - 1}
              />
            </ComposedChart>
          </ResponsiveContainer>

          <br />
          <Divider />
          <h3 style={{ fontFamily: "'Oswald', sans-serif" }}>
            Index vs Currency Chart
          </h3>
          <IndexCurrency />
        </div>
      ) : (
        "Loading..."
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openErrorMessage}
        onClose={() => {
          setOpenErrorMessage(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenErrorMessage(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error Loading Data.{" "}
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setOpenErrorMessage(false);
              refreshData();
            }}
          >
            Reload
          </Button>
        </Alert>
      </Snackbar>
    </>
  );
}
