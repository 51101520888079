export const getPredictionByStock = async (stock) => {
  return await fetch(
    `${window.location.protocol}//api.asxsardines.com.au/predictData/${stock}`
  )
    .then((res) => res.json())
    .catch(console.error);
};


export const getPredictionByStockLag = async (stock) => {
  return await fetch(
    `${window.location.protocol}//api.asxsardines.com.au/predictLagData/${stock}`
  )
    .then((res) => res.json())
    .catch(console.error);
};
