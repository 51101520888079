import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Divider } from "@material-ui/core";
import { Auth } from "aws-amplify";

export default function ControlNavigation(props) {
  const isOpen = props.isOpen;
  const setActiveRoute = props.setActiveRoute;
  const setIsOpen = props.setIsOpen;
  const activeRoute = props.activeRoute;

  return (
    <Drawer anchor={"left"} open={isOpen} onClose={() => setIsOpen(false)}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onKeyDown={() => setIsOpen(false)}
      >
        <List>
          {[
            "Home",
            "Notifications",
            "Sectors",
            "Z Scores",
            "StockAnalytics",
            "Put-Call Ratio",
            "Volatility Models",
            "Historical Volatility Outliers",
            "Historical Analytics",
            "Net Liquidity",
            "TS Fresh",
          ].map((text, index) => (
            <ListItemButton
              button
              key={text}
              onClick={(event) => {
                setActiveRoute(text);
                setIsOpen(false);
              }}
              selected={activeRoute === text}
            >
              <ListItemText primary={text} />
            </ListItemButton>
          ))}
          <Divider />
          <ListItemButton
            button
            key={"Sign Out"}
            onClick={async () => {
              try {
                await Auth.signOut();
              } catch (error) {
                console.error(error);
              }
            }}
          >
            <ListItemText primary={"Sign Out"} />
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
  );
}
