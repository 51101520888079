import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import currencyFormatter from "../utils/utils";

export default function IndexCurrency(props) {
  const [indexCurrency, setIndexCurrency] = useState(undefined);
  const [indexCurrency1, setIndexCurrency1] = useState(undefined);
  const indices = [
    { label: "S&P 500", value: "^GSPC" },
    { label: "NASDAQ Composite", value: "^IXIC" },
    { label: "Dow Jones", value: "^DJI" },
  ];
  const [minCurrency, setMinCurrency] = useState(0);
  const [maxCurrency, setMaxCurrency] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState("^GSPC");
  const [selectIndexLabel, setSelectedIndexLabel] = useState("S&P 500");
  useEffect(() => {
    if (!selectedIndex) {
      return;
    }
    setSelectedIndexLabel(
      indices?.find((e) => e?.value === selectedIndex)?.label
    );
    fetch(
      `${window.location.protocol}//api.asxsardines.com.au/indexCurrency/${selectedIndex}`
    )
      .then((res) => res.json())
      .then((res) => {
        setIndexCurrency1(
          res?.indexCurr
            ?.map((e, i) => {
              return {
                date: String(e?.date)?.split("T")[0],
                "Index Vs Currency": e?.close,
                Index: res?.index?.find((f) => f?.date === e?.date)?.close,
                Currency: res?.usdAud?.find((f) => f?.date === e?.date)?.close,
              };
            })
            .reverse()
        );
        setMinCurrency(Math.min(...res?.usdAud?.map((e) => e?.close)));
        setMaxCurrency(Math.max(...res?.usdAud?.map((e) => e?.close)));
      })
      .catch(console.error);
  }, [selectedIndex]);

  useEffect(() => {
    if (!indexCurrency1) {
      return;
    }
    const m1 = Math.max(...indexCurrency1?.map((e) => e?.Index));
    const m2 = Math.min(...indexCurrency1?.map((e) => e?.Index));

    const M1 = Math.max(
      ...indexCurrency1?.map((e) => e?.["Index Vs Currency"])
    );
    const M2 = Math.min(
      ...indexCurrency1?.map((e) => e?.["Index Vs Currency"])
    );
    console.log(
      indexCurrency1?.map((e) => ({
        date: e?.date,
        Index: (e?.Index - m2) / (m1 - m2),
        IndexVal: e?.Index,
        "Index Vs Currency": (e?.["Index Vs Currency"] - M2) / (M1 - M2),
      }))
    );
    setIndexCurrency(
      indexCurrency1?.map((e) => ({
        ...e,
        date: e?.date,
        Index: (e?.Index - m2) / (m1 - m2),
        IndexVal: e?.Index,
        "Index Vs Currency": (e?.["Index Vs Currency"] - M2) / (M1 - M2),
      }))
    );
  }, [indexCurrency1, minCurrency, maxCurrency]);

  return (
    <div style={{ width: "100%", height: 500 }}>
      <Select
        value={selectedIndex}
        onChange={(e) => {
          setSelectedIndex(e?.target?.value);
        }}
      >
        {indices?.map((e) => {
          return <MenuItem value={e?.value}>{e?.label}</MenuItem>;
        })}
      </Select>
      <ResponsiveContainer>
        <LineChart
          width={500}
          height={200}
          data={indexCurrency}
          margin={{
            top: 5,
            right: 30,
            left: 20,
          }}
          syncId="1"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            domain={["dataMin - 200", "dataMax + 200"]}
            angle={-60}
            tickFormatter={(value) => currencyFormatter(value)}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              return (
                <div
                  className="custom-tooltip"
                  style={{
                    backgroundColor: "#ffff",
                    padding: "5px",
                    border: "1px solid #cccc",
                    alignContent: "center",
                  }}
                >
                  <label
                    style={{
                      fontSize: 15,
                      fontFamily: "'Oswald', sans-serif",
                    }}
                  >
                    <b>Date: </b>
                    {payload?.[0]?.payload?.date}
                  </label>
                  <br />
                  <label
                    style={{
                      fontSize: 15,
                      fontFamily: "'Oswald', sans-serif",
                      color: "#413ea0",
                    }}
                  >
                    <b>{selectIndexLabel}: </b>
                    {currencyFormatter(
                      parseFloat(payload?.[0]?.payload?.["IndexVal"]).toFixed(2)
                    )}
                  </label>
                  <br />
                </div>
              );
            }}
          />
          <Legend
            payload={[
              {
                id: "IndexVal",
                // type: "square",
                value: selectIndexLabel,
                color: "#413ea0",
              },
            ]}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="IndexVal"
            stroke="#413ea0"
            activeDot={{ r: 8 }}
            dot={false}
          />
          <Brush
            height={0}
            width={0}
            dataKey="date"
            startIndex={indexCurrency?.length - 300}
            endIndex={indexCurrency?.length - 1}
          />
        </LineChart>
      </ResponsiveContainer>

      <ResponsiveContainer>
        <LineChart
          width={500}
          height={300}
          data={indexCurrency}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          syncId="1"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            angle={-60}
            tickFormatter={(value) => currencyFormatter(value)}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              return (
                <div
                  className="custom-tooltip"
                  style={{
                    backgroundColor: "#ffff",
                    padding: "5px",
                    border: "1px solid #cccc",
                    alignContent: "center",
                  }}
                >
                  <label
                    style={{
                      fontSize: 15,
                      fontFamily: "'Oswald', sans-serif",
                    }}
                  >
                    <b>Date: </b>
                    {payload?.[0]?.payload?.date}
                  </label>
                  <br />
                  <label
                    style={{
                      fontSize: 15,
                      fontFamily: "'Oswald', sans-serif",
                      color: "#33b864",
                    }}
                  >
                    <b>{selectIndexLabel} Vs USD/AUD: </b>
                    {currencyFormatter(
                      parseFloat(
                        payload?.[0]?.payload?.["Index Vs Currency"]
                      ).toFixed(2)
                    )}
                  </label>
                  <br />
                  <label
                    style={{
                      fontSize: 15,
                      fontFamily: "'Oswald', sans-serif",
                      color: "#413ea0",
                    }}
                  >
                    <b>{selectIndexLabel}: </b>
                    {currencyFormatter(
                      parseFloat(payload?.[0]?.payload?.["Index"]).toFixed(2)
                    )}
                  </label>
                  <br />
                  <label
                    style={{
                      fontSize: 15,
                      fontFamily: "'Oswald', sans-serif",
                      color: "#cd5c5c",
                    }}
                  >
                    <b>USD/AUD: </b>
                    {currencyFormatter(
                      parseFloat(payload?.[0]?.payload?.Currency).toFixed(2)
                    )}
                  </label>
                  <br />
                </div>
              );
            }}
          />
          <Legend />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="Index Vs Currency"
            stroke="#33b864"
            activeDot={{ r: 8 }}
            dot={false}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="Index"
            stroke="#413ea0"
            activeDot={{ r: 8 }}
            dot={false}
          />
          <Brush
            dataKey="date"
            startIndex={indexCurrency?.length - 300}
            endIndex={indexCurrency?.length - 1}
          />
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer>
        <LineChart
          width={500}
          data={indexCurrency}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          syncId="1"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            angle={-60}
            tickFormatter={(value) => currencyFormatter(value)}
            domain={[
              minCurrency - minCurrency * 0.05,
              maxCurrency + maxCurrency * 0.05,
            ]}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              return (
                <div
                  className="custom-tooltip"
                  style={{
                    backgroundColor: "#ffff",
                    padding: "5px",
                    border: "1px solid #cccc",
                    alignContent: "center",
                  }}
                >
                  <label
                    style={{
                      fontSize: 15,
                      fontFamily: "'Oswald', sans-serif",
                    }}
                  >
                    <b>Date: </b>
                    {payload?.[0]?.payload?.date}
                  </label>
                  <br />
                  <label
                    style={{
                      fontSize: 15,
                      fontFamily: "'Oswald', sans-serif",
                      color: "#cd5c5c",
                    }}
                  >
                    <b>USD/AUD: </b>
                    {currencyFormatter(
                      parseFloat(payload?.[0]?.payload?.["Currency"]).toFixed(2)
                    )}
                  </label>
                  <br />
                </div>
              );
            }}
          />
          <Legend />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="Currency"
            stroke="#cd5c5c"
            activeDot={{ r: 8 }}
            dot={false}
          />
          <Brush
            height={0}
            width={0}
            dataKey="date"
            startIndex={indexCurrency?.length - 300}
            endIndex={indexCurrency?.length - 1}
          />
          {/*  */}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
