import React, { Component } from 'react';
import { ComposedChart, Bar, Brush, YAxis, XAxis, CartesianGrid, ReferenceLine, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@material-ui/core';
import currencyFormatter from '../utils/utils';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';

const xAxisTickFormatter = (price_tick) => {
    return "$ " + String(price_tick)
}

export default class ControlConsolidatedExpiryChart extends Component {
    constructor(props) {
        super(props);
        this.renderBarChart = this.renderBarChart.bind(this);
        this.renderCallBars = this.renderCallBars.bind(this);
        this.renderPutBars = this.renderPutBars.bind(this);
        this.CustomTooltip = this.CustomTooltip.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            hexCall: ["#7CFC00", "#32CD32", "#228B22", "#006400", "#ADFF2F", "#9ACD32", "#00FF7F", "#00FA9A", "#8FBC8F", "#20B2AA", "#2E8B57"],
            hexPut: ["#FFA07A", "#FA8072", "#F08080", "#CD5C5C", "#DC143C", "#B22222", "#FF0000", "#8B0000", "#FF6347", "#FF4500", "#DB7093"],
            selectedState: 'default',
        };
    }

    renderCallBars = () => {
        var callBarList = [];
        callBarList = this.props.data.map((e, i) => {
            if (this.props.axisSwitch === true) {
                return <Bar stackId="c" key={String(Object.keys(e)[0]) + " callAbsoluteInterest" + String(i)} dataKey={String(Object.keys(e)[0]) + " callAbsoluteInterest"} fill={this.state.hexCall[i % 11]} />;
            }
            else {
                return <Bar stackId="a" key={String(Object.keys(e)[0]) + " call" + String(i)} dataKey={String(Object.keys(e)[0]) + " call"} fill={this.state.hexCall[i % 11]} />;
            }
        });
        return callBarList;
    }
    renderPutBars = () => {
        var putBarList = [];
        putBarList = this.props.data.map((e, i) => {
            if (this.props.axisSwitch === true) {
                return <Bar stackId="d" key={String(Object.keys(e)[0]) + " putAbsoluteInterest" + String(i)} dataKey={String(Object.keys(e)[0]) + " putAbsoluteInterest"} fill={this.state.hexPut[i % 11]} />;
            }
            else {
                return <Bar stackId="b" key={String(Object.keys(e)[0]) + " put" + String(i)} dataKey={String(Object.keys(e)[0]) + " put"} fill={this.state.hexPut[i % 11]} />;
            }
        });
        return putBarList;
    }
    CustomTooltip = ({ active, payload, label }) => {
        try {
            if (active) {
                //console.log(payload[0].payload);
                var temp = [];
                var presentExpiry = '';
                var expirySwitch = false;
                Object.keys(payload[0].payload).forEach(e => {
                    if (presentExpiry !== String(e.substring(0, 11))) {
                        presentExpiry = String(e.substring(0, 11));
                        expirySwitch = false;
                    }
                    if (e.length > 10) {
                        if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'].length > 0) {
                            if (!expirySwitch) {
                                if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['call'] > 0) {
                                    temp.push(
                                        <label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['callCode'])}
                                            style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}>
                                            <b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['callCode'])}</b>
                                            {` European Call OI: ${payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['call']}`}<br />
                                        </label>
                                    );
                                }
                                if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['put'] > 0) {
                                    temp.push(
                                        <label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['putCode'])}
                                            style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}>
                                            <b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['putCode'])}</b>
                                            {` European Put OI: ${payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['put']}`}<br />
                                        </label>
                                    );
                                }
                                if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['call'] > 0) {
                                    temp.push(
                                        <label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['callCode'])}
                                            style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}>
                                            <b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['callCode'])}</b>
                                            {` America Call OI: ${payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['call']}`}<br />
                                        </label>
                                    );
                                }
                                if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['put'] > 0) {
                                    temp.push(
                                        <label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['putCode'])}
                                            style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}>
                                            <b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['putCode'])}</b>
                                            {` America Put OI: ${payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['put']}`}<br />
                                        </label>
                                    );
                                }
                                expirySwitch = true;
                            }
                        }

                        if (!expirySwitch) {
                            if (e.substring(e.length - 4) === 'call') {
                                if (payload[0].payload[String(e.substring(0, 11)) + 'call']) {
                                    temp.push(<label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'callCode'])} style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'callCode'])}</b>{` Call OI: ${payload[0].payload[String(e.substring(0, 11)) + 'call']}`}<br /></label>);
                                }
                            }
                            if (e.substring(e.length - 3) === 'put') {
                                if (payload[0].payload[String(e.substring(0, 11)) + 'put']) {
                                    temp.push(<label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'putCode'])} style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'putCode'])}</b>{` Put OI: ${payload[0].payload[String(e.substring(0, 11)) + 'put']}`}<br /></label>);
                                }
                            }
                        }
                    }
                });
                return (
                    <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc', alignContent: 'center' }}>
                        <label style={{ color: "#FBB03B", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Strike Price: $</b>{` ${payload[1].payload.name}`}</label><br />
                        {temp}
                        {/* <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Average Open Interest:</b>{` ${payload[1].payload.averageOI}`}</label> */}
                    </div>
                );
            }

            return null;
        }
        catch(e)
        {
            return '';
        }
    };

    handleChange = (event) => {
        this.setState({ selectedState: event.target.value });
    };

    renderBarChart = (e) => {
        return (
            ''
        );
    }

    render() {
        if (this.props.chartData !== undefined && this.props.priceLastPivot !== undefined) {
            return (
                <div style={{ height: '300px' }}>
                    <FormControl component="fieldset" >
                        <RadioGroup aria-label="position" name="position" value={this.state.selectedState} onChange={this.handleChange} row>
                            <FormControlLabel
                                value="default"
                                control={<Radio color="primary" />}
                                label={<span style={{ fontFamily: "'Oswald', sans-serif" }}>Default</span>}
                                labelPlacement="bottom"
                            />
                            <FormControlLabel
                                value="call"
                                control={<Radio color="primary" />}
                                label={<span style={{ fontFamily: "'Oswald', sans-serif" }}>Call</span>}
                                labelPlacement="bottom"
                            />
                            <FormControlLabel
                                value="put"
                                control={<Radio color="primary" />}
                                style={{ fontFamily: "'Oswald', sans-serif" }}
                                label={<span style={{ fontFamily: "'Oswald', sans-serif" }}>Put</span>}
                                labelPlacement="bottom"
                            />
                        </RadioGroup>
                    </FormControl>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart style={{ flex: 1 }} data={this.props.chartData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis angle={-60} textAnchor="end" tickFormatter={(item)=>this.props.axisSwitch?currencyFormatter(item):item.toLocaleString()}>
                                <Label fontFamily="'Oswald', sans-serif" angle={-90} value={this.props.axisSwitch ? "Market Value" : "Open Interest"} position='insideLeft' style={{ textAnchor: 'middle', marginLeft: '100px' }} />
                            </YAxis>
                            <Legend />
                            <Tooltip content={<this.CustomTooltip />} />
                            {this.state.selectedState === 'default' || this.state.selectedState === 'call' ? this.renderCallBars() : null}
                            {this.state.selectedState === 'default' || this.state.selectedState === 'put' ? this.renderPutBars() : null}
                            <ReferenceLine x={this.props.priceLastPivot} stroke="black" label={"Last Price ($ " + String(this.props.priceLast.toFixed(2)) + ")"} strokeDasharray="3 3" />
                            {/* <Line type="monotone" dataKey="averageOI" fill="#76EE00" /> */}
                            <Brush tickFormatter={xAxisTickFormatter} dataKey="name" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            );
        }
        return "";
    }
}