import React, { Component } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, ComposedChart } from 'recharts';
import { Line, XAxis, YAxis, Brush, CartesianGrid, Tooltip, Legend } from 'recharts';
import optionList from '../data/optionList';
import ZScoreTableComponent from '../components/ZScoreTableComponent';

const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <text x={cx} y={cy} dy={25} textAnchor="middle" fill={fill}>
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} style={{textOverflow:'ellipsis'}} textAnchor={textAnchor} fill="#333">{`Composition ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey}  textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text> */}
        </g>
    );
};

export default class ControlDonutChart extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            sectorComposition: null,
            sectorChart: null,
            selectedSector: 'Utilities',
            colors: null,
            activeIndex: 0,
            normalize: false,
            sectorList: { 'Utilities': 'xuj', 'Consumer Defensive': 'xdj', 'Consumer Cyclical': 'xsj', 'Energy': 'xej', 'Financial Services': 'xfj', 'Healthcare': 'xhj', 'Industrials': 'xnj', 'Technology': 'xij', 'Basic Materials': 'xmj', 'Communication Services': 'xtj' },
            sectorOptions: [],
            sectorCodes: [],
            startIndex: 0,
            sectorChartNormalized: null
        }
        this.onPieEnter = this.onPieEnter.bind(this);
        this.onSectorSelected = this.onSectorSelected.bind(this);
        this.onSectorChanged = this.onSectorChanged.bind(this);
        this.onDateFiltered = this.onDateFiltered.bind(this);
    }
    componentDidMount() {
        fetch(window.location.protocol + '//api.asxsardines.com.au/sectorCompanyComposition', {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            credentials: 'omit'
        }).then(res => res.json()).then(res => {
            let obj = {};
            Object.keys(res.data).map(e => {
                obj[res.data[e].index] = res.data[e].id;
                return obj;
            })
            this.setState({ sectorCodes: obj });
        });

        fetch(window.location.protocol + '//api.asxsardines.com.au/sectorList/171', {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            credentials: 'omit'
        }).then(res => res.json()).then(res => {
            res.data = res.data.filter(e => optionList.includes(e.stock_code.substring(4, e.stock_code.length)))
            const options = res?.data?.map(e => <option key={e.stock_code} value={String(e.stock_code).substring(4, e.stock_code.length).toUpperCase()}  >{String(e.company_name)}</option>);
            // ;
            this.setState({ sectorOptions: options });
        });

        fetch(window.location.protocol + '//api.asxsardines.com.au/sectorComposition', {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            credentials: 'omit'
        }).then(res => res.json()).then(res => {
            var sectorsList = [];
            var i = 0;
            this.state.selectedSector = res.labels[0];
            this.onSectorChanged(this.state.selectedSector, this.state.normalize);
            for (var label of res['labels']) {
                sectorsList.push({ 'name': label, 'value': parseFloat(res['datasets'][0]['data'][i]) });
                i += 1;
            }
            this.setState({ color: res['datasets'][0]['backgroundColor'] })
            this.setState({ sectorComposition: sectorsList });
        });

    }
    onPieEnter = (_, index) => {
        this.setState({
            activeIndex: index,
        });
    };
    onSectorSelected = (_, index) => {
        //console.log(_.payload.name, this.state.sectorList[_.payload.name]);
        if ('Real Estate' !== _.payload.name) {
            this.state.selectedSector = _.payload.name;
            this.onSectorChanged(this.state.selectedSector, this.state.normalize);
        }

    };
    onSectorChanged = async (sector, normalize) => {
        if(this?.state?.sectorList?.[sector]===undefined){
            return;
        }
        fetch(window.location.protocol + `//api.asxsardines.com.au/sectorList/${this.state.sectorCodes[String(this.state.sectorList[sector]).toUpperCase()]}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            credentials: 'omit'
        }).then(res => res.json()).then(res => {
            if (res?.data !== undefined) {
                res.data = res?.data?.filter(e => optionList.includes(e.stock_code.substring(4, e.stock_code.length)))
                const options = res?.data?.map(e => <option key={e.stock_code} value={String(e.stock_code).substring(4, e.stock_code.length).toUpperCase()}  >{String(e.company_name)}</option>);
                // ;
                this.setState({ sectorOptions: options });
            }

        });
        fetch(window.location.protocol + '//api.asxsardines.com.au/requestSector/' + String(this.state.sectorList[sector]) + '/' + false, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            credentials: 'omit'
        }).then(res => res.json()).then(res => {
            var lineData = [];
            for (var i = 0; i < res.Dates.length; i++) {
                var dump = {};
                dump['date'] = res.Dates[i].split('T')[0];
                dump[String(this.state.sectorList[sector]).toUpperCase()] = parseFloat(res.Elements[0]['ComponentSeries'][3]['Values'][i]);
                dump['XJO'] = parseFloat(res.Elements[1]['ComponentSeries'][3]['Values'][i] / 100).toFixed(2);
                lineData.push(dump);
            }
            this.setState({ sectorChart: lineData });
            this.setState({ sectorChartNormalized: JSON.parse(JSON.stringify(this.state.sectorChart)) });
            this.onDateFiltered(0);
        });
    };
    normalizeChart = () => {
        this.state.normalize = !this.state.normalize;
        this.onSectorChanged(this.state.selectedSector, this.state.normalize);
    };
    onDateFiltered = async (startIndex) => {
        if (this.state.sectorChart?.length > 0) {
            //this.setState({ sectorChartNormalized: JSON.parse(JSON.stringify(this.state.sectorChart)) });
            var i = 0;
            var prev = {};
            var normalizedDump = [];
            await this.state.sectorChart.slice(startIndex, this.state.sectorChart.length).forEach(e => {
                let obj = {};
                Object.keys(e).map(f => {
                    if (!["date"].includes(f)) {
                        if (i === 0) {
                            prev[f] = e[f];
                            obj[f] = 0
                        }
                        else {
                            obj[f] = e[f] / prev[f] - 1;
                            prev[f] = e[f];
                        }
                    }
                    else {
                        obj[f] = e[f]
                    }
                })
                i += 1;
                normalizedDump.push(obj);
            })
            Array.prototype.splice.apply(this.state.sectorChartNormalized,[startIndex, normalizedDump.length+startIndex].concat(normalizedDump)); 
            this.forceUpdate();
        }
        // console.log("Chart",this.state.sectorChart, startIndex);
        // console.log("Normal Chart",this.state.sectorChartNormalized);
    }

    render() {
        return (
            this.state.sectorComposition != null
                ? (
                    <div>
                        <div style={{
                            // Other styles
                            display: 'flex',
                            flexDirection: 'row',
                            height: 400,
                            marginTop: 50
                        }} >

                            <PieChart width={400} height={400} style={{ marginLeft: 50 }}>
                                <Pie
                                    activeIndex={this.state.activeIndex}
                                    activeShape={renderActiveShape}
                                    data={this.state.sectorComposition}
                                    cx={150}
                                    cy={180}
                                    innerRadius={100}
                                    outerRadius={120}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="value"
                                    onMouseEnter={this.onPieEnter}
                                    onMouseDown={this.onSectorSelected}
                                >
                                    {this.state.sectorComposition.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={this.state.color[index % this.state.color.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                            <div style={{
                                // Other styles
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%'
                            }} >
                                {/* <span><Switch color="primary" onChange={this.normalizeChart} /> <label style={{ alignContent: 'left', marginLeft: -10, fontFamily: "'Oswald', sans-serif" }}><b>Normalize Chart</b></label>
                                </span> */}
                                <ResponsiveContainer width="100%" height="100%">
                                    <ComposedChart
                                        data={this.state.sectorChartNormalized}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 0,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="XJO" stroke="#8884d8" dot={false} />
                                        <Line type="monotone" dataKey={String(this.state.sectorList[this.state.selectedSector]).toUpperCase()} dot={false} stroke="#82ca9d" />
                                        <Brush onChange={(e) => {
                                            this.onDateFiltered(e.startIndex);
                                        }} dataKey="date" />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div>
                            <ZScoreTableComponent sectorOptions={this.state.sectorOptions} />
                        </div>
                    </div>
                ) :
                '');
    }
}