import { Select } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import optionList from "../data/optionList";
import DataTable from "react-data-table-component";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
  ReferenceLine,
  // Label,
  Surface,
  Symbols,
  Bar,
  BarChart,
  Scatter,
  ComposedChart,
} from "recharts";
import currencyFormatter from "../utils/utils";
import { Alert, Box, Button, CircularProgress, Snackbar } from "@mui/material";

function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
}

export default function HistoricalVolatility() {
  const [histVolatility, setHistoricalVolatility] = useState(undefined);
  const [historicalPrice, setHistoricalPrice] = useState(undefined);
  const [anomalies, setAnomalies] = useState(undefined);
  const [tableData, setTableData] = useState(undefined);
  const [selectedStock, setSelectedStock] = useState("XJO");
  const [isLoading, setIsLoading] = useState(false);
  const [putCallRatio, setPutCallRatio] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  // const [putCallVolume, setPutCallVolume] = useState(undefined);
  // const [minPutCallRatio, setMinPutCallRatio] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100);
  // const [maxPutCallRatio, setMaxPutCallRatio] = useState(100);
  const [maxPutCall, setMaxPutCall] = useState(1);

  const [openErrorMessage, setOpenErrorMessage] = useState(false);

  const newOptionList = [
    ...optionList,
    "xuj",
    "xdj",
    "xsj",
    "xej",
    "xfj",
    "xhj",
    "xnj",
    "xij",
    "xmj",
    "xtj",
    "snp500",
    "dji",
    "nasdaq",
  ];
  const getOptionLabel = (e) => {
    switch (e) {
      case "snp500":
        return "S&P 500";
      case "nasdaq":
        return "NASDAQ COMPOSITE";
      case "dji":
        return "DOW JONES";
      default:
        return "";
    }
  };
  const options = newOptionList.map((e, i) => {
    return (
      <option key={i} value={e}>
        {!["snp500", "dji", "nasdaq"].includes(e)
          ? String(e).toUpperCase()
          : getOptionLabel(e)}
      </option>
    );
  });
  // const colors = {
  //   others: "#40ee86",
  //   meat: "#67d6c0",
  //   pasta: "#127197",
  //   pizza: "#e96d8d",
  // };
  const columns = [
    {
      name: <b>Date</b>,
      selector: "date",
      sortable: true,
    },
    {
      name: <b>Price</b>,
      selector: "price",
      sortable: false,
    },
  ];
  const columns2 = [
    {
      name: <b>Date</b>,
      selector: "date",
      sortable: false,
    },
    {
      name: <b>Price</b>,
      selector: "price",
      sortable: false,
    },
    {
      name: <b>Calls</b>,
      selector: (row) =>
        "$" + convertToInternationalCurrencySystem(row?.callVolumeNotional),
      sortable: false,
    },
    {
      name: <b>Puts</b>,
      selector: (row) =>
        "$" + convertToInternationalCurrencySystem(row?.putVolumeNotional),
      sortable: false,
    },
    {
      name: <b>Net</b>,
      selector: (row) =>
        "$" + convertToInternationalCurrencySystem(row?.netNotional),
      sortable: false,
    },
  ];

  const getStockData = async (stock) => {
    if (!stock) {
      return;
    }
    window.document.title =
      String(stock).toUpperCase() + " Historical Volatility";
    setIsLoading(true);
    await fetch(
      `https://aio16ate1c.execute-api.us-west-2.amazonaws.com/default/HistoricalVolatility?symbol=${stock}`
    )
      .then((res) => res.json())
      .then((res) => {
        setHistoricalVolatility(res);
      })
      .catch((err) => {
        setOpenErrorMessage(true);
        console.error(err);
      });
    // setPutCallRatio([]);

    await fetch(
      `https://llmb8bkst1.execute-api.us-west-2.amazonaws.com/default/RDS_Connection?symbol=${String(
        stock
      ).toUpperCase()}&action=RETRIEVE_PUTCALL`
    )
      .then((res) => res.json())
      .then((res) => {
        res.reverse();
        // setPutCallVolume(
        //   res?.map((e) => {
        //     if (new Date(e?.updatedDate) > new Date("2022-05-29")) {
        //       return {
        //         callVolume: e?.putCallData?.consolidateCall,
        //         putVolume: e?.putCallData?.consolidatePut,
        //         updatedDate: e?.updatedDate,
        //       };
        //     } else {
        //       return {
        //         updatedDate: e?.updatedDate,
        //       };
        //     }
        //   })
        // );
        setPutCallRatio(
          res.map((e) => {
            return {
              date: String(e?.updatedDate).split("T")[0],
              ...e?.putCallData,
            };
          })
        );
      })
      .catch((err) => {
        // setOpenErrorMessage(true);
        console.error(err);
      });
    setIsLoading(false);

    // fetch(
    //   `${window.location.protocol}//api.asxsardines.com.au/putCallHistorical/${selectedStock}`
    // )
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setPutCallRatio(
    //       res?.result.map((e) => {
    //         return {
    //           date: String(e?.updatedDate).split("T")[0],
    //           ...JSON.parse(e?.putCallData),
    //         };
    //       })
    //     );
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  };

  useEffect(() => {
    getStockData(selectedStock);
  }, [selectedStock]);

  function GetZPercent(z) {
    // z == number of standard deviations from the mean

    // if z is greater than 6.5 standard deviations from the mean the
    // number of significant digits will be outside of a reasonable range

    if (z < -6.5) {
      return 0.0;
    }

    if (z > 6.5) {
      return 1.0;
    }

    var factK = 1;
    var sum = 0;
    var term = 1;
    var k = 0;
    var loopStop = Math.exp(-23);

    while (Math.abs(term) > loopStop) {
      term =
        (((0.3989422804 * Math.pow(-1, k) * Math.pow(z, k)) /
          (2 * k + 1) /
          Math.pow(2, k)) *
          Math.pow(z, k + 1)) /
        factK;
      sum += term;
      k++;
      factK *= k;
    }

    sum += 0.5;

    return sum;
  }

  const calculateMeanStd = (cMean) => {
    let arrayLength = cMean.reduce((a, b) => {
      if (b[0] && !isNaN(b[0])) {
        return a + 1;
      } else {
        return a;
      }
    }, 0);
    let mean =
      cMean.reduce((a, b) => {
        if (b[0] && !isNaN(b[0])) {
          return a + b[0];
        } else {
          return a;
        }
      }, 0) / arrayLength;

    let arr = cMean.map((el) => {
      return (el[0] - mean) ** 2;
    });

    let total = arr.reduce((acc, curr) => acc + curr, 0);

    let std = Math.sqrt(total / cMean.length);

    let ZScore = cMean.map((e) => [GetZPercent((e[0] - mean) / std), e[1]]);
    // return {
    //   result: [...ZScore1, ...ZScore2],
    //   mean1,
    //   mean2,
    //   std1,
    //   std2,
    //   ZScore1,
    //   ZScore2,
    // };
    return ZScore.reduce((acc, i) => {
      acc[i[1]] = i[0];
      return acc;
    }, {});
  };

  useEffect(() => {
    if (histVolatility?.data) {
      const temp = JSON.parse(histVolatility?.data ?? "{}");
      let prices = Object.keys(temp?.price).map((e, i) => {
        let date = undefined;
        try {
          date = new Date(e.split("T")?.[0]).toISOString().split("T")?.[0];
        } catch (error) {
          date = new Date(parseInt(e)).toISOString().split("T")?.[0];
        }

        return {
          date: date,
          price: temp?.price?.[e],
          putCallRatio: parseFloat(
            putCallRatio?.[Object.keys(temp?.price).length - i - 1]
              ?.consolidatePutCallRatio
          ).toFixed(2),
          puts: parseFloat(
            putCallRatio?.[Object.keys(temp?.price).length - i - 1]
              ?.consolidatePut
          ).toFixed(2),
          calls: parseFloat(
            putCallRatio?.[Object.keys(temp?.price).length - i - 1]
              ?.consolidateCall
          ).toFixed(2),
          putVolume:
            new Date(date) > new Date("2022-05-29")
              ? parseFloat(
                  putCallRatio?.find((v) => v?.date === date)?.consolidatePut
                ).toFixed(2)
              : 0,
          callVolume:
            new Date(date) > new Date("2022-05-29")
              ? parseFloat(
                  putCallRatio?.find((v) => v?.date === date)?.consolidateCall
                ).toFixed(2)
              : 0,
        };
      });
      let cMean = prices
        .map((e) => {
          return [parseFloat(e?.calls), e?.date];
        })
        .filter((e) => !isNaN(e[0]));
      let pMean = prices
        .map((e) => {
          return [parseFloat(e?.puts), e?.date];
        })
        .filter((e) => !isNaN(e[0]));

      let cMeanStd = calculateMeanStd(cMean);
      let pMeanStd = calculateMeanStd(pMean);
      prices.map((e, i) => {
        e.calls = parseFloat(cMeanStd?.[e?.date]).toFixed(2);
        e.puts = -parseFloat(pMeanStd?.[e?.date]).toFixed(2);
        e.putCallRatio = parseFloat(
          (cMeanStd?.[e?.date] - pMeanStd?.[e?.date]) /
            (pMeanStd?.[e?.date] + cMeanStd?.[e?.date])
        ).toFixed(2);
        return undefined;
      });
      // console.log(
      //   calculateMeanStd(cMean),
      //   calculateMeanStd(pMean),
      //   prices,
      //   Math.max(
      //     ...prices
      //       .map((e) => parseFloat(e?.putCallRatio))
      //       .filter((e) => !isNaN(e))
      //   ),
      //   Math.min(
      //     ...prices
      //       .map((e) => parseFloat(e?.putCallRatio))
      //       .filter((e) => !isNaN(e))
      //   ),
      //   prices.map((e) => parseFloat(e?.putCallRatio)).filter((e) => !isNaN(e))
      // );

      setMinPrice(
        Math.min(Object.keys(temp?.price).map((e) => temp?.price?.[e])) - 20
      );
      setMaxPrice(
        Math.max(Object.keys(temp?.price).map((e) => temp?.price?.[e])) + 20
      );
      setMaxPutCall(
        Math.max(
          ...prices
            .map((e) =>
              Math.max(
                parseFloat(e?.callVolume ?? 0),
                parseFloat(e?.putVolume ?? 0)
              )
            )
            .filter((e) => !isNaN(e))
        ) + 20
      );
      // setMinPutCallRatio(
      //   Math.min(
      //     ...prices
      //       .map((e) => parseFloat(e?.putCallRatio).toFixed(2))
      //       .filter((e) => !isNaN(e))
      //   )
      // );
      // setMaxPutCallRatio(
      //   Math.max(
      //     ...prices
      //       .map((e) => parseFloat(e?.putCallRatio).toFixed(2))
      //       .filter((e) => !isNaN(e))
      //   )
      // );
      const pAnomalies = {};
      pAnomalies["IsolationForest"] = Object.keys(temp?.anomaly)
        .map((e) => {
          let date = undefined;
          try {
            date = new Date(e).toISOString().split("T")?.[0];
          } catch (error) {
            date = new Date(parseInt(e)).toISOString().split("T")?.[0];
          }
          return temp?.anomaly?.[e] === -1 ? date : undefined;
        })
        .filter((e) => e !== undefined);
      pAnomalies["STL"] = Object.keys(temp?.anomalies)
        .map((e) => {
          let date = undefined;
          try {
            date = new Date(e).toISOString().split("T")?.[0];
          } catch (error) {
            date = new Date(parseInt(e)).toISOString().split("T")?.[0];
          }
          return temp?.anomalies?.[e] ? date : undefined;
        })
        .filter((e) => e !== undefined);
      pAnomalies["21_day_anomaly"] = Object.keys(temp?.["21_day_anomaly"])
        .map((e) => {
          let date = undefined;
          try {
            date = new Date(e).toISOString().split("T")?.[0];
          } catch (error) {
            date = new Date(parseInt(e)).toISOString().split("T")?.[0];
          }
          return temp?.["21_day_anomaly"]?.[e] ? date : undefined;
        })
        .filter((e) => e !== undefined);
      const pTableData = {};
      pTableData["STL"] = pAnomalies?.["STL"].map((e) => {
        let price = undefined;
        try {
          price = temp?.["price"]?.[String(e) + "T00:00:00"];
          if (price === undefined) {
            price = parseFloat(
              temp?.["price"]?.[new Date(e).getTime()]
            ).toFixed(2);
          }
        } catch (error) {}
        return { date: e, price: price };
      });
      pTableData["IsolationForest"] = pAnomalies?.["IsolationForest"].map(
        (e) => {
          let price = undefined;
          try {
            price = temp?.["price"]?.[String(e) + "T00:00:00"];
            if (price === undefined) {
              price = parseFloat(
                temp?.["price"]?.[new Date(e).getTime()]
              ).toFixed(2);
            }
          } catch (error) {}
          return { date: e, price: price };
        }
      );
      pTableData["21_day_anomaly"] = pAnomalies?.["21_day_anomaly"].map((e) => {
        let price = undefined;
        try {
          price = temp?.["price"]?.[String(e) + "T00:00:00"];
          if (price === undefined) {
            price = parseFloat(
              temp?.["price"]?.[new Date(e).getTime()]
            ).toFixed(2);
          }
        } catch (error) {}
        return { date: e, price: price };
      });

      setTableData(pTableData);
      setAnomalies(pAnomalies);
      const notionalConstant = selectedStock === "XJO" ? 10 : 100;
      prices = prices?.map((e) => ({
        ...e,
        callVolumeNotional: e?.callVolume * e?.price * notionalConstant,
        putVolumeNotional: e?.putVolume * e?.price * notionalConstant,
        netNotional:
          e?.callVolume * e?.price * notionalConstant -
          e?.putVolume * e?.price * notionalConstant,
      }));
      prices = prices?.map((e, i) => {
        if (i > 0) {
          if (
            new Date(prices?.[i - 1]?.date).getMonth() !==
            new Date(e?.date).getMonth()
          ) {
            return { ...e, newMonth: e?.price };
          }
          return e;
        }
        return e;
      });
      setHistoricalPrice(prices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [histVolatility, putCallRatio]);

  // useEffect(() => {
  //   if (!selectedStock) {
  //     return;
  //   }
  //   console.log("selectedStock", selectedStock);
  //   fetch(
  //     window.location.protocol +
  //       "//api.asxsardines.com.au/requestData/" +
  //       String(selectedStock).toUpperCase(),
  //     {
  //       method: "GET", // *GET, POST, PUT, DELETE, etc.
  //       credentials: "omit",
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((res) => {
  //       var dates = [];
  //       let static_data;
  //       console.log(
  //         "Hello",
  //         res?.["data"]?.["items"]?.map((k) => {
  //           static_data = k["exerciseGroups"]
  //             .map((e) => {
  //               if (e["call"]["volume"] !== 0 || e["put"]["volume"] !== 0) {
  //                 const notionalConstant = selectedStock === "XJO" ? 10 : 100;

  //                 var resid = {};
  //                 resid["expiry"] = k["date"];
  //                 resid["call"] = e["call"]["symbol"];
  //                 resid["callPriceExercise"] = e["call"]["priceExercise"];
  //                 resid["callTheo"] = isNaN(Number(e["call"]["theo"]))
  //                   ? ""
  //                   : Number(e["call"]["theo"]).toFixed(2);
  //                 resid["callDeemed"] = isNaN(Number(e["call"]["deemed"]))
  //                   ? ""
  //                   : Number(e["call"]["deemed"]).toFixed(2);
  //                 resid["callNotional"] =
  //                   e["call"]["volume"] *
  //                   e["call"]["priceExercise"] *
  //                   notionalConstant;
  //                 resid["callPriceAsk"] = isNaN(Number(e["call"]["priceAsk"]))
  //                   ? ""
  //                   : Number(e["call"]["priceAsk"]).toFixed(2);
  //                 resid["callPriceBid"] = isNaN(Number(e["call"]["priceBid"]))
  //                   ? ""
  //                   : Number(e["call"]["priceBid"]).toFixed(2);
  //                 resid["callStyle"] = e["call"]["style"];
  //                 resid["callOpenInterest"] = e["call"]["openInterest"];
  //                 resid["callVolume"] = e["call"]["volume"];
  //                 resid["callPriceLast"] = isNaN(Number(e["call"]["priceLast"]))
  //                   ? ""
  //                   : Number(e["call"]["priceLast"]).toFixed(2);

  //                 resid["put"] = e["put"]["symbol"];
  //                 resid["putPriceExercise"] = e["put"]["priceExercise"];
  //                 resid["putTheo"] = isNaN(Number(e["put"]["theo"]))
  //                   ? ""
  //                   : Number(e["put"]["theo"]).toFixed(2);
  //                 resid["putDeemed"] = isNaN(Number(e["put"]["deemed"]))
  //                   ? ""
  //                   : Number(e["put"]["deemed"]).toFixed(2);
  //                 resid["putNotional"] =
  //                   e["put"]["volume"] *
  //                   e["put"]["priceExercise"] *
  //                   notionalConstant;
  //                 resid["putPriceAsk"] = isNaN(Number(e["put"]["priceAsk"]))
  //                   ? ""
  //                   : Number(e["put"]["priceAsk"]).toFixed(2);
  //                 resid["putPriceBid"] = isNaN(Number(e["put"]["priceBid"]))
  //                   ? ""
  //                   : Number(e["put"]["priceBid"]).toFixed(2);
  //                 resid["putStyle"] = e["put"]["style"];
  //                 resid["putOpenInterest"] = e["put"]["openInterest"];
  //                 resid["putVolume"] = e["put"]["volume"];
  //                 resid["putPriceLast"] = isNaN(Number(e["put"]["priceLast"]))
  //                   ? ""
  //                   : Number(e["put"]["priceLast"]).toFixed(2);
  //                 return resid;
  //               }
  //               return undefined;
  //             })
  //             ?.filter((e) => e !== undefined);
  //           dates.push(k["date"]);
  //           var obj = {};
  //           obj[k["date"]] = static_data;
  //           return obj;
  //         })
  //       );
  //     });
  //   fetch(
  //     window.location.protocol +
  //       "//api.asxsardines.com.au/getPrevDayOptions/" +
  //       selectedStock
  //   )
  //     .then((res) => res.json())
  //     .then((res) => {
  //       console.log(
  //         "result here: ",
  //         // JSON.stringify(res),
  //         res?.data?.items?.reduce(
  //           (acc, e) => [
  //             ...acc,
  //             ...e?.exerciseGroups?.reduce(
  //               (acc, j) => [
  //                 ...acc,
  //                 ...[
  //                   { symbol: j?.call?.symbol, type: "call" },
  //                   { symbol: j?.put?.symbol, type: "put" },
  //                 ],
  //               ],
  //               []
  //             ),
  //           ],
  //           []
  //         )
  //       );
  //     })
  //     .catch(console.error);
  // }, [selectedStock]);

  const renderCustomizedLegend = ({ payload }, fullLegend = false) => {
    return (
      <div className="customized-legend">
        {payload.map((entry, i) => {
          const { dataKey, color } = entry;
          // const active = _.includes(this.state.disabled, dataKey);
          const active = false;
          const style = {
            marginRight: 10,
            color: active ? "#AAA" : "#000",
          };

          return (
            <span
              key={`legend-${i}`}
              className="legend-item"
              // onClick={() => this.handleClick(dataKey)}
              style={style}
            >
              <Surface width={10} height={10} viewBox="0 0 10 10">
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {active && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type="circle"
                    size={25}
                    fill={"#FFF"}
                  />
                )}
              </Surface>
              <span>{dataKey}</span>
            </span>
          );
        })}
        {fullLegend === true && (
          <span
            className="legend-item"
            // onClick={() => this.handleClick(dataKey)}
            style={{
              marginRight: 10,
              // color: active ? "#AAA" : "#000",
            }}
          >
            <Surface width={10} height={10} viewBox="0 0 10 10">
              <Symbols cx={5} cy={5} type="circle" size={50} fill={"green"} />
              {/* {active && (
              <Symbols cx={5} cy={5} type="circle" size={25} fill={"#FFF"} />
            )} */}
            </Surface>
            <span>Isolation Forest Method</span>
          </span>
        )}
        {fullLegend === true && (
          <span
            className="legend-item"
            // onClick={() => this.handleClick(dataKey)}
            style={{
              marginRight: 10,
              // color: active ? "#AAA" : "#000",
            }}
          >
            <Surface width={10} height={10} viewBox="0 0 10 10">
              <Symbols cx={5} cy={5} type="circle" size={50} fill={"red"} />
              {/* {active && (
              <Symbols cx={5} cy={5} type="circle" size={25} fill={"#FFF"} />
            )} */}
            </Surface>
            <span>Seasonal Trend Decomposition</span>
          </span>
        )}
        {fullLegend === true && (
          <span
            className="legend-item"
            // onClick={() => this.handleClick(dataKey)}
            style={{
              marginRight: 10,
              // color: active ? "#AAA" : "#000",
            }}
          >
            <Surface width={10} height={10} viewBox="0 0 10 10">
              <Symbols cx={5} cy={5} type="circle" size={50} fill={"yellow"} />
              {/* {active && (
              <Symbols cx={5} cy={5} type="circle" size={25} fill={"#FFF"} />
            )} */}
            </Surface>
            <span>Stardard Deviation Outliers</span>
          </span>
        )}
      </div>
    );
  };

  return !isLoading ? (
    <>
      <Select
        native
        onChange={(e) => {
          setSelectedStock(e.target.value);
        }}
        value={selectedStock}
        style={{
          width: "300px",
          margin: 10,
          fontFamily: "'Oswald', sans-serif",
        }}
      >
        {options}
      </Select>
      {historicalPrice ? (
        <ResponsiveContainer width="100%" height={450}>
          <ComposedChart
            width={500}
            height={300}
            data={historicalPrice}
            margin={{
              right: 30,
              left: 20,
            }}
            syncId="1"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis domain={[minPrice, maxPrice]}></YAxis>
            <Tooltip />
            <Legend
              verticalAlign="bottom"
              height={10}
              align="left"
              content={({ payload }) =>
                renderCustomizedLegend({ payload }, true)
              }
            />
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="price"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              dot={false}
            />
            <Scatter dataKey="newMonth" fill="#DE970B" shape="square" />
            {anomalies !== undefined
              ? anomalies?.["IsolationForest"].map((e, i) => {
                  return (
                    <ReferenceLine
                      x={e}
                      stroke="green"
                      key={`isolationforest-${i}`}
                    >
                      {/* <Label position="left" fontSize={10}>
                        IF Method
                      </Label> */}
                    </ReferenceLine>
                  );
                })
              : ""}

            {anomalies !== undefined
              ? anomalies?.["STL"].map((e, i) => {
                  return (
                    <ReferenceLine x={e} stroke="red" key={`stl-${i}`}>
                      {/* <Label position="right" fontSize={10}>
                        STL Method
                      </Label> */}
                    </ReferenceLine>
                  );
                })
              : ""}

            {anomalies !== undefined
              ? anomalies?.["21_day_anomaly"].map((e, i) => {
                  return (
                    <ReferenceLine x={e} stroke="yellow" key={`21day-${i}`}>
                      {/* <Label
                        position="bottom"
                        offset={-155}
                        viewBox={{ width: 10 }}
                        fontSize={10}
                      >
                        STD Method
                      </Label> */}
                    </ReferenceLine>
                  );
                })
              : ""}
            {/* <Brush /> */}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <div>Loading...</div>
      )}
      <br />

      {historicalPrice && (
        <ResponsiveContainer width="100%" height={150}>
          <BarChart
            width={500}
            height={100}
            data={historicalPrice}
            margin={{
              right: 30,
              left: 20,
            }}
            syncId="1"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis domain={[0, maxPutCall]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="callVolume" fill="#82ca9d" />
            <Bar dataKey="putVolume" fill="#ff0000" />
          </BarChart>
        </ResponsiveContainer>
      )}
      <br />
      {historicalPrice && (
        <ResponsiveContainer width="100%" height={150}>
          <BarChart
            width={500}
            height={100}
            data={historicalPrice}
            margin={{
              right: 30,
              left: 20,
            }}
            syncId="1"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis domain={[0, maxPutCall]} />
            <Tooltip
              formatter={(value) =>
                isNaN(value) ? value : currencyFormatter(value)
              }
            />
            <Legend />
            <Bar dataKey="callVolumeNotional" fill="#82ca9d" />
            <Bar dataKey="putVolumeNotional" fill="#ff0000" />
          </BarChart>
        </ResponsiveContainer>
      )}
      <br />
      {historicalPrice ? (
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            width={500}
            height={100}
            data={historicalPrice}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            syncId="1"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            {/* <YAxis domain={[minPutCallRatio, maxPutCallRatio]}></YAxis> */}
            <YAxis domain={[-1, 1]}></YAxis>
            <Tooltip />
            {/* <Legend /> */}
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="blue" />
                <stop offset={`50%`} stopColor="blue" />
                <stop offset={`50%`} stopColor="red" />
                <stop offset="100%" stopColor="red" />
              </linearGradient>
            </defs>
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="putCallRatio"
              // stroke="#8884d8"
              stroke="url(#gradient)"
              // stroke={(d) => (d > 0 ? "#8884d8" : "#8884d8")}
              activeDot={{ r: 8 }}
              dot={false}
            />
            {/* <Brush /> */}
            <Legend
              verticalAlign="bottom"
              height={36}
              align="left"
              content={renderCustomizedLegend}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        ""
      )}

      {console.log(historicalPrice)}
      {historicalPrice ? (
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            width={500}
            height={100}
            data={historicalPrice}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            syncId="1"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            {/* <YAxis domain={[0, maxPutCall]}></YAxis> */}
            <YAxis domain={[-1, 1]}></YAxis>
            <Tooltip />
            {/* <Legend /> */}
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="puts"
              stroke="#ff0000"
              activeDot={{ r: 8 }}
              dot={false}
            />
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="calls"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              dot={false}
            />
            <Brush />
            <Legend
              verticalAlign="bottom"
              height={36}
              align="left"
              content={renderCustomizedLegend}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        ""
      )}

      {historicalPrice && (
        <>
          <h3>Notional Value Table</h3>
          <DataTable
            dense={true}
            overflowY={true}
            style={{ minHeight: 340 }}
            noHeader={true}
            pagination={true}
            data={[...historicalPrice].reverse()}
            columns={columns2}
          />
        </>
      )}

      {tableData ? (
        <>
          <h3>Standard Deviation</h3>
          <DataTable
            dense={true}
            overflowY={true}
            style={{ minHeight: 340 }}
            noHeader={true}
            pagination={true}
            defaultSortField="date"
            defaultSortAsc={false}
            data={tableData["21_day_anomaly"]}
            columns={columns}
          />
          <h3>Isolation Forest</h3>
          <DataTable
            dense={true}
            overflowY={true}
            style={{ minHeight: 340 }}
            noHeader={true}
            defaultSortAsc={false}
            pagination={true}
            defaultSortField="date"
            data={tableData["IsolationForest"]}
            columns={columns}
          />
          <h3>STL</h3>
          <DataTable
            dense={true}
            overflowY={true}
            style={{ minHeight: 340 }}
            defaultSortField="date"
            defaultSortAsc={false}
            noHeader={true}
            pagination={true}
            data={tableData["STL"]}
            columns={columns}
          />
        </>
      ) : (
        ""
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openErrorMessage}
        onClose={() => {
          setOpenErrorMessage(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenErrorMessage(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error Loading Data.{" "}
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setOpenErrorMessage(false);
              getStockData(selectedStock);
            }}
          >
            Reload
          </Button>
        </Alert>
      </Snackbar>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "90vh",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
