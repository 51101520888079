import React, { Component } from 'react';
import { AreaChart, Area, YAxis, XAxis, CartesianGrid, ReferenceLine, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import Grid from '@material-ui/core/Grid';
import currencyFormatter from '../utils/utils';

export default class ControlConsolidatedStackedArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hexCall: ["#7CFC00", "#32CD32", "#228B22", "#006400", "#ADFF2F", "#9ACD32", "#00FF7F", "#00FA9A", "#8FBC8F", "#20B2AA", "#2E8B57"],
            hexPut: ["#FFA07A", "#FA8072", "#F08080", "#CD5C5C", "#DC143C", "#B22222", "#FF0000", "#8B0000", "#FF6347", "#FF4500", "#DB7093"]
        };
        this.renderCallArea = this.renderCallArea.bind(this);
        this.renderPutArea = this.renderPutArea.bind(this);
        this.CustomTooltipCall = this.CustomTooltipCall.bind(this);
        this.CustomTooltipPut = this.CustomTooltipPut.bind(this);
    }


    CustomTooltipCall = ({ active, payload, label }) => {
        if (active) {
            var temp = [];
            var presentExpiry = '';
            var expirySwitch = false;
            Object.keys(payload[0].payload).forEach(e => {
                if (presentExpiry !== String(e.substring(0, 11))) {
                    presentExpiry = String(e.substring(0, 11));
                    expirySwitch = false;
                }
                if (e.length > 10) {
                    if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'].length > 0) {
                        if (!expirySwitch) {
                            if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['call'] > 0) {
                                temp.push(
                                    <label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['callCode'])}
                                        style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}>
                                        <b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['callCode'])}</b>
                                        {` European Call OI: ${payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['call']}`}<br />
                                    </label>
                                );
                            }
                            if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['call'] > 0) {
                                temp.push(
                                    <label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['callCode'])}
                                        style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}>
                                        <b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['callCode'])}</b>
                                        {` America Call OI: ${payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['call']}`}<br />
                                    </label>
                                );
                            }

                            expirySwitch = true;
                        }
                    }

                    if (!expirySwitch) {

                        if (e.substring(e.length - 4) === 'call') {
                            if (payload[0].payload[String(e.substring(0, 11)) + 'call']) {
                                temp.push(<label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'callCode'])} style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'callCode'])}</b>{` Call OI: ${payload[0].payload[String(e.substring(0, 11)) + 'call']}`}<br /></label>);
                            }
                        }
                    }
                }
            });
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc', alignContent: 'center' }}>
                    <label style={{ color: "#FBB03B", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Strike Price: $</b>{` ${payload[0].payload.name}`}</label><br />
                    {temp}
                    {/* <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Average Open Interest:</b>{` ${payload[1].payload.averageOI}`}</label> */}
                </div>
            );
        }

        return null;
    };

    CustomTooltipPut = ({ active, payload, label }) => {
        if (active) {
            var temp = [];
            var presentExpiry = '';
            var expirySwitch = false;
            Object.keys(payload[0].payload).forEach(e => {
                if (presentExpiry !== String(e.substring(0, 11))) {
                    presentExpiry = String(e.substring(0, 11));
                    expirySwitch = false;
                }
                if (e.length > 10) {
                    if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'].length > 0) {
                        if (!expirySwitch) {
                            if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['put'] > 0) {
                                temp.push(
                                    <label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['putCode'])}
                                        style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}>
                                        <b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['putCode'])}</b>
                                        {` European Put OI: ${payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][0]['put']}`}<br />
                                    </label>
                                );
                            }
                            if (payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['put'] > 0) {
                                temp.push(
                                    <label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['putCode'])}
                                        style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}>
                                        <b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['putCode'])}</b>
                                        {` America Put OI: ${payload[0].payload[String(e.substring(0, 11)) + 'leafEuropean'][1]['put']}`}<br />
                                    </label>
                                );
                            }

                            expirySwitch = true;
                        }
                    }
                    if (!expirySwitch) {
                        if (e.substring(e.length - 3) === 'put') {
                            if (payload[0].payload[String(e.substring(0, 11)) + 'put']) {
                                temp.push(<label key={e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'putCode'])} style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>{e.substring(0, 11) + String(payload[0].payload[String(e.substring(0, 11)) + 'putCode'])}</b>{` Put OI: ${payload[0].payload[String(e.substring(0, 11)) + 'put']}`}<br /></label>);
                            }
                        }
                    }
                }
            });
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc', alignContent: 'center' }}>
                    <label style={{ color: "#FBB03B", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Strike Price: $</b>{` ${payload[0].payload.name}`}</label><br />
                    {temp}
                    {/* <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Average Open Interest:</b>{` ${payload[1].payload.averageOI}`}</label> */}
                </div>
            );
        }

        return null;
    };

    renderCallArea = () => {
        var callAreaList = [];
        callAreaList = Object.keys(this.props.expiryCumSum).map((e, i) => {
            if (this.props.axisSwitch === true) {
                return <Area stackId="c" key={String(e) + " callAbsoluteInterest" + String(i)} dataKey={String(e) + " callAbsoluteInterest"} fill={this.state.hexCall[i % 11]} />
            }
            else {
                return <Area stackId="a" key={String(e) + " call" + String(i)} dataKey={String(e) + " call"} fill={this.state.hexCall[i % 11]} />
            }
        });
        return callAreaList;
    }
    renderPutArea = () => {
        var callAreaList = [];
        callAreaList = Object.keys(this.props.expiryCumSum).map((e, i) => {
            if (this.props.axisSwitch === true) {
                return <Area stackId="d" key={String(e) + " putAbsoluteInterest" + String(i)} dataKey={String(e) + " putAbsoluteInterest"} fill={this.state.hexPut[i % 11]} />
            }
            else {
                return <Area stackId="b" key={String(e) + " put" + String(i)} dataKey={String(e) + " put"} fill={this.state.hexPut[i % 11]} />
            }
        });
        return callAreaList;
    }
    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <div style={{ height: '300px', marginLeft: '20px' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                width={600}
                                height={400}
                                data={this.props.cumChartData}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <Legend />
                                <XAxis dataKey="name" />
                                <YAxis angle={-60} textAnchor="end" tickFormatter={(item)=>this.props.axisSwitch?currencyFormatter(item):item.toLocaleString()}>
                                    <Label fontFamily="'Oswald', sans-serif" angle={-90} value={this.props.axisSwitch ? "Market Value" : "Open Interest"} position='insideLeft' style={{ textAnchor: 'middle' }} />
                                </YAxis>
                                <Tooltip content={<this.CustomTooltipCall />} />
                                {this.renderCallArea()}
                                <ReferenceLine x={this.props.priceLastPivot} stroke="black" label={"Last Price ($ " + String(this.props.priceLast.toFixed(2)) + ")"} strokeDasharray="3 3" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ height: '300px' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                width={600}
                                height={400}
                                data={this.props.cumChartData}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <Legend />
                                <XAxis dataKey="name" />
                                <YAxis angle={-60} textAnchor="end" tickFormatter={(item)=>this.props.axisSwitch?currencyFormatter(item):item.toLocaleString()}>
                                    <Label fontFamily="'Oswald', sans-serif" angle={-90} value={this.props.axisSwitch ? "Market Value" : "Open Interest"} position='insideLeft' style={{ textAnchor: 'middle' }} />
                                </YAxis>
                                <Tooltip content={<this.CustomTooltipPut />} />
                                {this.renderPutArea()}
                                <ReferenceLine x={this.props.priceLastPivot} stroke="black" label={"Last Price ($ " + String(this.props.priceLast.toFixed(2)) + ")"} strokeDasharray="3 3" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </Grid></Grid >);
    }
}