import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import ControlOptionOLHist from '../controls/ControlOptionOLHist.js';
import ControlConsolidatedExpiryChart from '../controls/ControlConsolidatedExpiryChart.js';
import ControlConsolidatedStackedArea from '../controls/ControlConsolidatedStackedArea.js';
import { connect } from 'react-redux';
import ControlZScoreChart from '../controls/ControlZScoreChart';
import { StockContext } from '../contexts/StockContext.js';
import optionList from '../data/optionList';

class OptionInfoTable extends Component {
    constructor(props) {
        super(props);

        this.state =
        {
            columns: [
                {
                    name: 'Code',
                    selector: 'call',
                    sortable: true,

                },
                {
                    name: 'Style',
                    selector: 'callStyle',
                    sortable: true
                },
                {
                    name: 'Offer ($)',
                    selector: 'callPriceAsk',
                    sortable: true
                },
                {
                    name: 'Bid ($)',
                    selector: 'callPriceBid',
                    sortable: true
                },
                { name: 'Open Interest', selector: 'callOpenInterest', sortable: true, cell: data => parseInt(data?.callOpenInterest).toLocaleString() },
                { name: 'Volume', selector: 'callVolume', sortable: true, cell: data => parseInt(data?.callVolume).toLocaleString() },
                { name: 'Last Trade ($)', selector: 'callPriceLast', sortable: true },
                //{ name: 'Theoretical Price ($)', selector: 'callTheo', sortable: true },
                //{ name: 'Deemed Price ($)', selector: 'callDeemed', sortable: true },
                { name: 'Exercise Price ($)', selector: 'callPriceExercise', sortable: true },
                { name: 'Code', selector: 'put', sortable: true, style: { backgroundColor: '#C9D1D9' } },
                { name: 'Style', selector: 'putStyle', sortable: true, style: { backgroundColor: '#C9D1D9' } },
                { name: 'Offer ($)', selector: 'putPriceAsk', sortable: true, style: { backgroundColor: '#C9D1D9' } },
                { name: 'Bid ($)', selector: 'putPriceBid', sortable: true, style: { backgroundColor: '#C9D1D9' } },
                { name: 'Open Interest', selector: 'putOpenInterest', sortable: true, style: { backgroundColor: '#C9D1D9' }, cell: data => parseInt(data?.putOpenInterest).toLocaleString() },
                { name: 'Volume', selector: 'putVolume', sortable: true, style: { backgroundColor: '#C9D1D9' }, cell: data => parseInt(data?.putVolume).toLocaleString() },
                { name: 'Last Trade ($)', selector: 'putPriceLast', sortable: true, style: { backgroundColor: '#C9D1D9' } },
                //{ name: 'Theoretical Price ($)', selector: 'putTheo', sortable: true, style: {backgroundColor:'#778899'} },
                //{ name: 'Deemed Price ($)', selector: 'putDeemed', sortable: true, style: {backgroundColor:'#778899'} },
            ],
            axisSwitch: false,
            filterOIFlag: false,
            filterVolumeFlag: false,
            selectedStock: null,
            switchZScoreChart: false,
            filterZScoreByExpiry: false,
        }
        
        this.onOptionChanged = this.onOptionChanged.bind(this);
        this.onExpiryChanged = this.onExpiryChanged.bind(this);
    }
    componentDidMount() {
        var options = [];
        options = optionList.map((e, i) => {
            return <option key={i} value={e}  >{String(e)}</option>;
        });
        this.setState({ options: options, selectedStock: String(optionList[0]).toUpperCase() });
        this.props.dispatch({ type: 'STOCK', stock: String(optionList[0]).toUpperCase() });
        window.document.title = String(optionList[0]).toUpperCase() + " OI Analysis";
        fetch(window.location.protocol + '//api.asxsardines.com.au/requestData/' + String(optionList[0]).toUpperCase(), {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            credentials: 'omit'
        }).then(res => res.json()).then(res => {
            this.props.dispatch({ type: 'PROCESS_DATA', req: res });
        });
    }
    onOptionChanged = (e) => {
        this.props.dispatch({ type: 'STOCK', stock: e.target.value });
        this.setState({ selectedStock: e.target.value })
        window.document.title = String(e.target.value) + " OI Analysis";
        fetch(window.location.protocol + '//api.asxsardines.com.au/requestData/' + String(e.target.value).toUpperCase(), {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            credentials: 'omit'
        }).then(res => res.json()).then(res => {
            this.props.dispatch({ type: 'PROCESS_DATA', req: res });
        });
    }
    onExpiryChanged = (e) => {
        this.props.exerciseGroups.forEach(k => {
            if (e.target.value === Object.keys(k)[0]) {
                this.props.dispatch({ type: 'EXPIRY_CHANGED', expiry: Object.keys(k)[0], data: k[Object.keys(k)[0]] })
            }
        });
    }
    toggleFilterZScoreByExpiry = () =>{
        this.setState({filterZScoreByExpiry: !this.state.filterZScoreByExpiry});
    }
    toggleZScoreChart = () => {
        this.setState({ switchZScoreChart: !this.state.switchZScoreChart });
    }
    toggleYAxis = () => {
        this.setState({ axisSwitch: !this.state.axisSwitch });
    }
    filterOpenInterest = () => {
        this.setState({ filterOIFlag: !this.state.filterOIFlag });
    }
    filterVolume = () => {
        this.setState({ filterVolumeFlag: !this.state.filterVolumeFlag });
    }

    render() {
        return (this.state.columns != null && this.props.data != null
            ?
            <StockContext.Provider value={{ selectedStock: this.state.selectedStock, priceLast: this.props.priceLast, selectedExpiry: this.props.expiry, filterZScoreByExpiry: this.state.filterZScoreByExpiry }}>
                <div>
                    <div align="left" >
                        <Select
                            native
                            onChange={this.onOptionChanged}
                            style={{ width: '100px', margin: 10, fontFamily: "'Oswald', sans-serif" }}
                        >
                            {this.state.options}
                        </Select>
                        <Select
                            native
                            onChange={this.onExpiryChanged}
                            style={{ margin: 10, width: '150px', fontFamily: "'Oswald', sans-serif" }}
                        >
                            {this.props.expiryDates}
                        </Select>
                        <span style={{ marginLeft: 10 }}><Switch color="primary" value={this.state.axisSwitch} onChange={this.toggleYAxis} /> <label style={{ alignContent: 'left', marginLeft: -10, fontFamily: "'Oswald', sans-serif" }}><b>Toggle {this.state.axisSwitch?"Open Interest":"Market Value"}</b></label></span>
                        <span style={{ marginLeft: 10 }}><Switch color="primary" value={this.state.filterOIFlag} onClick={this.filterOpenInterest} /> <label style={{ alignContent: 'left', marginLeft: -10, fontFamily: "'Oswald', sans-serif" }}><b>Filter Open Interest</b></label></span>
                        <span style={{ marginLeft: 10 }}><Switch color="primary" value={this.state.filterVolumeFlag} onClick={this.filterVolume} /> <label style={{ alignContent: 'left', marginLeft: -10, fontFamily: "'Oswald', sans-serif" }}><b>Filter Volume</b></label></span>
                        <span style={{ marginLeft: 10 }}><Switch color="primary" value={this.state.switchZScoreChart} onClick={this.toggleZScoreChart} /> <label style={{ alignContent: 'left', marginLeft: -10, fontFamily: "'Oswald', sans-serif" }}><b>Toggle Z-Score Chart</b></label></span>
                        {this.state.switchZScoreChart?<span style={{ marginLeft: 10 }}><Switch color="primary" value={this.state.filterZScoreByExpiry} onClick={this.toggleFilterZScoreByExpiry} /> <label style={{ alignContent: 'left', marginLeft: -10, fontFamily: "'Oswald', sans-serif" }}><b>Filter by Expiry (Z-Score)</b></label></span>:void(0)}
                        <span style={{ marginLeft: 30 }}><label style={{ alignContent: 'left', marginLeft: -10, fontFamily: "'Oswald', sans-serif" }}><b>Last Price - ({this.props.consensusData?.find(e=>e.SYMBOL===String(this.state.selectedStock).toUpperCase())?.PRICE ?? '-'})</b></label></span>
                        <span style={{ marginLeft: 30 }}><label style={{ alignContent: 'left', marginLeft: -10, fontFamily: "'Oswald', sans-serif" }}><b>Consensus Target - ({this.props.consensusData?.find(e=>e.SYMBOL===String(this.state.selectedStock).toUpperCase())?.CONSENSUSTARGET ?? '-'})</b></label></span>
                    </div>
                    <div >
                        <Grid container spacing={3} style={{ fontFamily: "'Oswald', sans-serif", color: 'white', marginTop: 10, backgroundColor: 'black' }}>
                            <Grid item xs={6} style={{ marginTop: -9 }}>
                                Calls
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: -9 }}>
                                Puts
                            </Grid>
                        </Grid>
                        <DataTable dense={true} 
                        // overflowY={true}
                        style={{ minHeight: 350, maxHeight: 350 }} noHeader={true} pagination={true} data={this.state.filterOIFlag && !this.state.filterVolumeFlag ? this.props.filterOIData : (!this.state.filterOIFlag && this.state.filterVolumeFlag ? this.props.filterVolumeData : (this.state.filterOIFlag && this.state.filterVolumeFlag ? this.props.filteredData : this.props.data))} columns={this.state.columns} />
                        <div style={{ color: "#3d3d3d", fontFamily: "'Oswald', sans-serif" }}>
                            <h2 >{this.props.optionSelected}: {!this.state.switchZScoreChart ? 'Open Interest Chart' : 'Z-Score Chart'}</h2>
                        </div>
                        {this.state.switchZScoreChart ?
                            <div style={{ color: "#3d3d3d", fontFamily: "'Oswald', sans-serif" }}>
                                <h2 >Calls</h2>
                                <ControlZScoreChart style="Call"/>
                                <h2 >Puts</h2>
                                <ControlZScoreChart style="Put"/>
                            </div> :
                            (
                                <div>
                                    <div style={{ color: "#3d3d3d", fontFamily: "'Oswald', sans-serif" }}>
                                        <h2 >{this.props.expiry}</h2>
                                    </div>
                                    {this.props.chartData !== null ? <ControlOptionOLHist data={this.props.chartData} optionSelected={this.props.optionSelected} axisSwitch={this.state.axisSwitch} priceLast={this.props.priceLast} priceLastPivot={this.props.barPriceLastPivot} /> : ''}
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <div style={{ color: "#3d3d3d", fontFamily: "'Oswald', sans-serif" }}>
                                        <h2 >Consolidated Chart</h2>
                                    </div>
                                    {this.props.data !== undefined && this.props.chartDataConsolidated !== undefined ? <ControlConsolidatedExpiryChart data={this.props.exerciseGroups} chartData={this.props.chartDataConsolidated} priceLast={this.props.priceLast} priceLastPivot={this.props.priceLastPivot} axisSwitch={this.state.axisSwitch} /> : ''}
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    {this.props.data !== null && this.props.chartDataConsolidated !== undefined ? <ControlConsolidatedStackedArea data={this.props.exerciseGroups} chartData={this.props.chartDataConsolidated} priceLast={this.props.priceLast} priceLastPivot={this.props.priceLastPivot} axisSwitch={this.state.axisSwitch} expiryCumSum={this.props.expiryCumSum} cumChartData={this.props.cumChartData} /> : ''}
                                </div>
                            )
                        }
                    </div>
                </div>
            </StockContext.Provider>
            :
            null
        );
    }
}
function mapStateToProps(state) {
    if (state.data !== undefined) {
        state.filterOIData = state.data.filter(e => (e.putOpenInterest > 0 && e.putOpenInterest !== null) || (e.callOpenInterest > 0 && e.callOpenInterest !== null));
        state.filterVolumeData = state.data.filter(e => (e.putVolume > 0 && e.putVolume !== null) || (e.callVolume > 0 && e.callVolume !== null));
        state.filteredData = state.data.filter(e => (e.putVolume > 0 && e.putVolume !== null && e.putOpenInterest > 0 && e.putOpenInterest !== null) || (e.callVolume > 0 && e.callVolume !== null && e.callOpenInterest > 0 && e.callOpenInterest !== null));
    }
    return { optionSelected: state.stock, chartData: state.chartData, exerciseGroups: state.exerciseGroups, priceLast: state.priceLast, priceLastPivot: state.priceLastPivot, data: state.data, expiryDates: state.expiryDates, chartDataConsolidated: state.chartDataConsolidated, expiryCumSum: state.expiryCumSum, cumChartData: state.cumChartData, expiry: state.expiry, barPriceLastPivot: state.barPriceLastPivot, filterOIData: state.filterOIData, filterVolumeData: state.filterVolumeData, filteredData: state.filteredData };
};
export default connect(mapStateToProps)(OptionInfoTable);