import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Select from "@material-ui/core/Select";
import FormLabel from "@material-ui/core/FormLabel";
import optionList from "../data/optionList";
import Grid from "@material-ui/core/Grid";

import Switch from "@material-ui/core/Switch";

const columns = [
  {
    name: "Code",
    selector: "code",
    sortable: true,
  },
  {
    name: "Expiry",
    selector: "expiry",
    sortable: true,
  },
  {
    name: "Strike ($)",
    selector: "strike",
    sortable: true,
  },
  {
    name: "Type",
    selector: "type",
    sortable: true,
  },
  {
    name: "Exposure ($)",
    selector: "exposure",
    sortable: true,
  },
  {
    name: "Value ($)",
    selector: "value",
    sortable: true,
  },
  {
    name: "Volume",
    selector: "newVolume",
    sortable: true,
  },
];

export default function ControlNotifications(props) {
  const [filterByActivity, setFilterByActivity] = useState(true);
  const [dataPut, setDataPut] = useState(undefined);
  const [dataCall, setDataCall] = useState(undefined);
  const [dataLast, setDataLast] = useState(undefined);
  const [options, setOptions] = useState(undefined);
  const [optionFilterList, setOptionFilterList] = useState(undefined);
  const [selectedOption, setSelectedOption] = useState(undefined);

  const refreshData = async () => {
    var pOptions = [];
    pOptions = optionList.map((e, i) => {
      return (
        <option key={i} value={e}>
          {String(e)}
        </option>
      );
    });
    setOptions(pOptions);
    await fetch(
      window.location.protocol + "//api.asxsardines.com.au/analytics/A2M"
    )
      .then((res) => res.json())
      .then((res) => {
        //fetch('http://localhost:5000/analytics/A2M').then(res => res.json()).then(res => {
        setDataCall(res.calls);
        setDataPut(res.puts);
        setDataLast(res.last);
      });
    await fetch(
      window.location.protocol +
        "//api.asxsardines.com.au/getAnalyticsFilterList"
    )
      .then((res) => res.json())
      .then((res) => setOptionFilterList(res))
      .catch(console.error);
  };

  useEffect(() => {
    if (!optionFilterList) {
      return;
    }
    var pOptions = [];
    pOptions = optionList
      .map((e, i) => {
        if (
          !filterByActivity ||
          (filterByActivity && optionFilterList?.[String(e)] === true)
        ) {
          return (
            <option key={i} value={e}>
              {String(e)}
            </option>
          );
        } else {
          return undefined;
        }
      })
      .filter((e) => e !== undefined);
    const firstOption = filterByActivity
      ? Object.keys(optionFilterList)?.find(
          (e) => optionFilterList?.[e] === true
        )
      : Object.keys(optionFilterList)?.[0];
    setSelectedOption(firstOption);
    fetch(
      window.location.protocol +
        "//api.asxsardines.com.au/analytics/" +
        firstOption
    )
      .then((res) => res.json())
      .then((res) => {
        //fetch('http://localhost:5000/analytics/A2M').then(res => res.json()).then(res => {
        setDataCall(res.calls);
        setDataPut(res.puts);
        setDataLast(res.last);
      });
    setOptions(pOptions);
  }, [optionFilterList, filterByActivity]);

  const onOptionChanged = async (e) => {
    setSelectedOption(e?.target?.value);
    fetch(
      window.location.protocol +
        "//api.asxsardines.com.au/analytics/" +
        String(e.target.value).toUpperCase(),
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        credentials: "omit",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setDataCall(res.calls);
        setDataPut(res.puts);
        setDataLast(res.last);
      });
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <div>
      <div align="left">
        <Select
          native
          onChange={onOptionChanged}
          style={{
            width: "100px",
            margin: 10,
            fontFamily: "'Oswald', sans-serif",
          }}
          value={selectedOption}
        >
          {options}
        </Select>
        <FormLabel
          style={{
            margin: 10,
            fontFamily: "'Oswald', sans-serif",
            color: "black",
          }}
        >
          {" "}
          <b>{"Last Price: $ " + String(parseFloat(dataLast).toFixed(2))}</b>
        </FormLabel>
        <Switch
          onChange={() => {
            setFilterByActivity((preVal) => !preVal);
          }}
          checked={filterByActivity}
          color="primary"
        />
        <label
          style={{
            alignContent: "left",
            marginLeft: -10,
            fontFamily: "'Oswald', sans-serif",
          }}
        >
          <b>Filter by Activity</b>
        </label>
      </div>
      <div>
        <Grid
          container
          spacing={12}
          style={{
            fontFamily: "'Oswald', sans-serif",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            marginTop: 10,
            backgroundColor: "black",
          }}
        >
          Calls
        </Grid>
        <DataTable
          pagination={true}
          style={{ minHeight: 200, maxHeight: 350 }}
          columns={columns}
          dense={true}
          data={dataCall}
          noHeader={true}
        />
      </div>
      <div>
        <Grid
          container
          spacing={12}
          style={{
            fontFamily: "'Oswald', sans-serif",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            marginTop: 10,
            backgroundColor: "black",
          }}
        >
          Puts
        </Grid>
        <DataTable
          pagination={true}
          style={{ minHeight: 200, maxHeight: 350 }}
          columns={columns}
          dense={true}
          data={dataPut}
          noHeader={true}
        />
      </div>
    </div>
  );
}
