import React, { useEffect, useState } from "react";
import OptionInfoTable from "../components/OptionInfoTable.js";

export default function AnalysisToolHomeScreen(props) {
  const [consensusData, setConsensusData] = useState(undefined);
  useEffect(() => {
    fetch(window.location.protocol + "//api.asxsardines.com.au/consensus")
      .then((res) => res.json())
      .then((res) => {
        setConsensusData(res?.[0]);
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <div>
      <OptionInfoTable consensusData={consensusData} />
    </div>
  );
}
