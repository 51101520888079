import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getHistoricalAnalytics } from "../API";
import { Alert, Button, Snackbar } from "@mui/material";

const columns = [
  {
    id: "date",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Date
      </label>
    ),
    minWidth: 150,
    format: (e) => (
      <label
        tyle={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
          textAlign: "right",
        }}
      >
        {String(e).toUpperCase()}
      </label>
    ),
  },
  {
    id: "stl",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        STL
      </label>
    ),
    minWidth: 150,
    format: (e) => (
      <label
        tyle={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
          textAlign: "right",
        }}
      >
        {String(e).toUpperCase()}
      </label>
    ),
  },
  {
    id: "isolationForest",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Isolation Forest
      </label>
    ),
    minWidth: 150,
    format: (e) => (
      <label
        tyle={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
          textAlign: "right",
        }}
      >
        {String(e).toUpperCase()}
      </label>
    ),
  },
  {
    id: "stdDev",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        STD Dev
      </label>
    ),
    minWidth: 150,
    format: (e) => (
      <label
        tyle={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
          textAlign: "right",
        }}
      >
        {String(e).toUpperCase()}
      </label>
    ),
  },
];

export default function HistoricalAnalytics(props) {
  // const selectedStock = "XJO";
  // const [selectedStock, setSelectedStock] = useState("XJO");
  const [histAnalytics, setHistAnalytics] = useState(undefined);
  const [sortKey, setSortKey] = useState(undefined);
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [orderBy, setOrderBy] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const getOptionLabel = (e) => {
  //   switch (e) {
  //     case "snp500":
  //       return "S&P 500";
  //     case "nasdaq":
  //       return "NASDAQ COMPOSITE";
  //     case "dji":
  //       return "DOW JONES";
  //     default:
  //       return;
  //   }
  // };
  // const options = newOptionList.map((e, i) => {
  //   return (
  //     <option key={i} value={e}>
  //       {!["snp500", "dji", "nasdaq"].includes(e)
  //         ? String(e).toUpperCase()
  //         : getOptionLabel(e)}
  //     </option>
  //   );
  // });
  useEffect(() => {
    getHistoricalAnalytics()
      .then((res) => setHistAnalytics(res))
      .catch((err) => {
        setOpenErrorMessage(true);
        console.error(err);
      });
    // }, [selectedStock]);
  }, []);
  return (
    <>
      {/* <Select
        native
        onChange={(e) => {
          setSelectedStock(e.target.value);
        }}
        value={selectedStock}
        style={{
          width: "300px",
          margin: 10,
          fontFamily: "'Oswald', sans-serif",
        }}
      >
        {options}
      </Select> */}
      {histAnalytics !== undefined ? (
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ height: 620 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={column.id}
                      // align={column.align}
                      style={{ minWidth: column.minWidth }}
                      onClick={() => setSortKey(column.id)}
                    >
                      <TableSortLabel
                        active={sortKey === column.id}
                        hideSortIcon={true}
                        direction={orderBy}
                        onClick={() => {
                          if (sortKey !== column.id) {
                            setSortKey(column.id);
                            setOrderBy("asc");
                          } else {
                            setOrderBy(orderBy === "asc" ? "desc" : "asc");
                          }
                        }}
                        sx={{
                          width: column.minWidth,
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {histAnalytics !== undefined
                  ? Object.keys(histAnalytics).map((row, index) =>
                      index > page * rowsPerPage - 1 &&
                      index < page * rowsPerPage + rowsPerPage ? (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.option}
                        >
                          <TableCell>{row}</TableCell>
                          <TableCell>
                            {histAnalytics?.[row]?.stl.join(", ")}
                          </TableCell>
                          <TableCell>
                            {histAnalytics?.[row]?.isolationForest.join(", ")}
                          </TableCell>
                          <TableCell>
                            {histAnalytics?.[row]?.stdDev.join(", ")}
                          </TableCell>
                          {/* {columns.map((column) => {
                            let value;
                            switch (column.id) {
                              case "option":
                                value = row?.[column.id];
                                break;
                              case "stl":
                                value = row?.[column.id]
                                  .map(
                                    (e) =>
                                      Object.keys(e).map((i) => {
                                        if (e[i] === true) {
                                          if (
                                            ![
                                              "snp500",
                                              "dji",
                                              "nasdaq",
                                            ].includes(row.option)
                                          ) {
                                            return String(i).split("T")[0];
                                          } else {
                                            return String(
                                              new Date(
                                                parseFloat(i)
                                              ).toISOString()
                                            ).split("T")[0];
                                          }
                                        }
                                      })[0]
                                  )
                                  .filter((e) => e !== undefined);
                                if (value?.length === 0) {
                                  value = "-";
                                } else {
                                  value = value.join(", ");
                                }
                                break;
                              case "isolationForest":
                                value = row?.[column.id]
                                  .map(
                                    (e) =>
                                      Object.keys(e).map((i) => {
                                        if (e[i] === -1) {
                                          if (
                                            ![
                                              "snp500",
                                              "dji",
                                              "nasdaq",
                                            ].includes(row.option)
                                          ) {
                                            return String(i).split("T")[0];
                                          } else {
                                            return String(
                                              new Date(
                                                parseFloat(i)
                                              ).toISOString()
                                            ).split("T")[0];
                                          }
                                        }
                                      })[0]
                                  )
                                  .filter((e) => e !== undefined);
                                if (value?.length === 0) {
                                  value = "-";
                                } else {
                                  value = value.join(", ");
                                }
                                break;
                              case "stdDev":
                                value = row?.[column.id]
                                  .map(
                                    (e) =>
                                      Object.keys(e).map((i) => {
                                        if (e[i] === true) {
                                          if (
                                            ![
                                              "snp500",
                                              "dji",
                                              "nasdaq",
                                            ].includes(row.option)
                                          ) {
                                            return String(i).split("T")[0];
                                          } else {
                                            return String(
                                              new Date(
                                                parseFloat(i)
                                              ).toISOString()
                                            ).split("T")[0];
                                          }
                                        }
                                      })[0]
                                  )
                                  .filter((e) => e !== undefined);
                                if (value?.length === 0) {
                                  value = "-";
                                } else {
                                  value = value.join(", ");
                                }
                                break;
                              default:
                                break;
                            }
                            return (
                              <TableCell
                                key={column.id + String(row.option)}
                                align={column.align}
                              >
                                {value !== undefined && value !== null
                                  ? column.format !== undefined &&
                                    value !== undefined &&
                                    value !== null
                                    ? column.format(value)
                                    : value
                                  : "-"}
                              </TableCell>
                            );
                          })} */}
                        </TableRow>
                      ) : (
                        void 0
                      )
                    )
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={
              Object.keys(histAnalytics)?.length === undefined
                ? 0
                : Object.keys(histAnalytics)?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        "Loading..."
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openErrorMessage}
        onClose={() => {
          setOpenErrorMessage(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenErrorMessage(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error Loading Data.{" "}
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setOpenErrorMessage(false);
              getHistoricalAnalytics()
                .then((res) => setHistAnalytics(res))
                .catch((err) => {
                  setOpenErrorMessage(true);
                  console.error(err);
                });
            }}
          >
            Reload
          </Button>
        </Alert>
      </Snackbar>
    </>
  );
}
