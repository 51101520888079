import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

const columns = [
    { id: 'code', label: <label style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100 }}>Code</label>, minWidth: 150 },
    { id: 'consolidatePut', label: <label style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100 }}>Put Volume</label>, minWidth: 150,
    format: (value) => <label tyle={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100, textAlign: "right" }}>{value.toLocaleString()}</label>
    },
    { id: 'consolidateCall', label: <label style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100 }}>Call Volume</label>, minWidth: 150,
    format: (value) => <label tyle={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100, textAlign: "right" }}>{value.toLocaleString()}</label>
    },
    { id: 'consolidateTotalVolume', label: <label style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100 }}>Total Volume</label>, minWidth: 100,
    format: (value) => <label tyle={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100, textAlign: "right" }}>{value.toLocaleString()}</label> },
    {
        id: 'consolidatePutCallRatio', label: <label style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100 }}>Put-Call Ratio</label>, minWidth: 150,
        format: (value) => String((value*100).toFixed(2))+"%"
    },
    { id: 'lastPrice', label: <label style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100 }}>Last Price ($)</label>, minWidth: 150 },
    { id: 'consensusTarget', label: <label style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100 }}>Consensus Target ($)</label>, minWidth: 150 },
]

export default function PutCallRatio(props) {
    const [putCallData, setPutCallData] = useState([]);
    const [sortKey, setSortKey] = useState(undefined);
    const [orderBy, setOrderBy] = useState('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [consensusData, setConsensusData] = useState(undefined);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        fetch(window.location.protocol + '//api.asxsardines.com.au/putcall').then(res => res.json()).then(setPutCallData);
        fetch(window.location.protocol + "//api.asxsardines.com.au/consensus")
        .then((res) => res.json())
        .then((res) => {
          setConsensusData(res?.[0]);
        })
        .catch((err) => console.error(err));
    }, []);

    const sortPutCallData = async (data) => {
        if (sortKey !== undefined) {
            const sortedData = await data.sort((a, b) => {
                if (
                    !isNaN(parseFloat(String(a[sortKey]).replace(",", "").replace("%", "").replace("$", "").trim())) &&
                    !isNaN(parseFloat(String(b[sortKey]).replace(",", "").replace("%", "").replace("$", "").trim()))
                ) {
                    if (
                        parseFloat(String(a[sortKey]).replace(",", "").replace("%", "").replace("$", "").trim())
                        <
                        parseFloat(String(b[sortKey]).replace(",", "").replace("%", "").replace("$", "").trim())
                    ) {
                        return orderBy === "asc" ? -1 : 1;
                    }
                    return orderBy === "asc" ? 1 : -1;
                }
                else {
                    if (String(a[sortKey]).toUpperCase() < String(b[sortKey]).toUpperCase()) {
                        return orderBy === "asc" ? -1 : 1;
                    }
                    else if (String(a[sortKey]).toUpperCase() > String(b[sortKey]).toUpperCase()) {
                        return orderBy === "asc" ? 1 : -1;
                    }
                }
                if (a[sortKey] === null || b[sortKey] === null) {
                    return orderBy === "asc" ? 1 : -1;
                }
                return 0;
            }).filter(a => a !== undefined);
            setPutCallData(sortedData);
        }
        else {
            setPutCallData(data);
        }
    }

    useEffect(() => {
        sortPutCallData(putCallData);
    }, [sortKey, orderBy])

    useEffect(() => {
        console.log(putCallData);
    }, [putCallData]);
    return (
        <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ height: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={column.id}
                                    // align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    onClick={() => setSortKey(column.id)}
                                >
                                    <TableSortLabel
                                        active={sortKey === column.id}
                                        hideSortIcon={true}
                                        direction={orderBy}
                                        onClick={() => {
                                            if (sortKey !== column.id) {
                                                setSortKey(column.id);
                                                setOrderBy('asc');
                                            }
                                            else {
                                                setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
                                            }
                                        }}
                                        sx={{
                                            width: column.minWidth
                                        }}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            putCallData !== undefined ?
                                putCallData.map((row, index) =>
                                    index > (page * rowsPerPage - 1) && index < (page * rowsPerPage + rowsPerPage) ?
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {
                                                columns.map((column) => {
                                                    var value = row?.[column.id];
                                                    if(['lastPrice', 'consensusTarget'].includes(column.id)){
                                                        // consensusData?.find(e=>e.SYMBOL===String(row?.code).toUpperCase())?.CONSENSUSTARGET;
                                                        switch(column.id){
                                                            case 'lastPrice':
                                                                value = consensusData?.find(e=>e.SYMBOL===String(row?.code).toUpperCase())?.PRICE;
                                                                break;
                                                            case 'consensusTarget':
                                                                value = consensusData?.find(e=>e.SYMBOL===String(row?.code).toUpperCase())?.CONSENSUSTARGET;
                                                                break;
                                                        }
                                                    }
                                                    return (
                                                        <TableCell key={column.id + String(row.code)} align={column.align}>
                                                            {
                                                                value !== undefined && value !== null ?
                                                                    column.format !== undefined && (value !== undefined && value !== null)
                                                                        ? column.format(value)
                                                                        :
                                                                        value
                                                                    :
                                                                    "-"
                                                            }
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                        :
                                        void (0)
                                )
                                : void (0)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={putCallData?.length === undefined ? 0 : putCallData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}