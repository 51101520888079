import optionList from "../data/optionList";

const newOptionList = [
  ...optionList,
  "xuj",
  "xdj",
  "xsj",
  "xej",
  "xfj",
  "xhj",
  "xnj",
  "xij",
  "xmj",
  "xtj",
  "snp500",
  "dji",
  "nasdaq",
];

export const getHistoricalAnalytics = async () => {
  return fetch(
    `${window.location.protocol}//api.asxsardines.com.au/getConsolidatedHistoricalVol`
  )
    .then((res) => res.json())
    .then(async (res) => {
      let dates = {};
      const processed_res = Object.keys(res)
        .map((e) => {
          try {
            let outlierData = JSON.parse(res?.[e]?.data);
            let stl = Object.keys(outlierData?.anomalies)
              .map((e) => {
                return { [e]: outlierData?.anomalies[e] };
              })
              .slice(-20)
              .reverse();
            let isolationForest = Object.keys(outlierData?.anomaly)
              .map((e) => {
                return { [e]: outlierData?.anomaly[e] };
              })
              .slice(-20)
              .reverse();
            let stdDev = Object.keys(outlierData?.["21_day_anomaly"])
              .map((e) => {
                return { [e]: outlierData?.["21_day_anomaly"][e] };
              })
              .slice(-20)
              .reverse();
            return {
              option: e,
              stl: stl,
              isolationForest: isolationForest,
              stdDev: stdDev,
            };
          } catch (ex) {
            console.error(e, res?.[e], ex);
            return undefined;
          }
        })
        .filter((e) => e !== undefined);
      let x = processed_res
        .map((e) => {
          if (
            e?.stl
              .map((i) => Object.keys(i).map((k) => i[k])[0])
              .includes(true) ||
            e?.isolationForest
              .map((i) => Object.keys(i).map((k) => i[k])[0])
              .includes(-1) ||
            e?.stdDev
              .map((i) => Object.keys(i).map((k) => i[k])[0])
              .includes(true)
          ) {
            return e;
          }
          return undefined;
        })
        .filter((e) => e !== undefined);
      await x.map(async (e) => {
        if (!newOptionList.includes(e.option)) {
          return;
        }
        try {
          if (!["snp500", "dji", "nasdaq"].includes(e.option)) {
            e?.isolationForest.map((ele) => {
              let dateKey = String(Object.keys(ele)[0]).split("T")[0];
              if (!dates?.[dateKey]) {
                console.log(dateKey, e);
                dates[dateKey] = {
                  stl: [],
                  stdDev: [],
                  isolationForest: [],
                };
              }
              return undefined;
            });
            await e.stl.map(async (ele) => {
              let dateKey = String(Object.keys(ele)[0]).split("T")[0];
              if (ele[Object.keys(ele)[0]]) {
                await dates?.[dateKey]?.stl.push(
                  String(e.option).toUpperCase()
                );
              }
            });
            await e.stdDev.map(async (ele) => {
              let dateKey = String(Object.keys(ele)[0]).split("T")[0];
              if (ele[Object.keys(ele)[0]]) {
                await dates?.[dateKey]?.stdDev.push(
                  String(e.option).toUpperCase()
                );
              }
            });
            await e.isolationForest.map(async (ele) => {
              let dateKey = String(Object.keys(ele)[0]).split("T")[0];
              if (ele[Object.keys(ele)[0]] === -1) {
                await dates?.[dateKey]?.isolationForest.push(
                  String(e.option).toUpperCase()
                );
              }
            });
          } else {
            e?.isolationForest.map((ele) => {
              let dateKey = new Date(parseFloat(Object.keys(ele)[0]))
                .toISOString()
                .split("T")[0];
              if (!dates?.[dateKey]) {
                dates[dateKey] = {
                  stl: [],
                  stdDev: [],
                  isolationForest: [],
                };
              }
              return undefined;
            });
            await e.stl.map(async (ele) => {
              let dateKey = new Date(parseFloat(Object.keys(ele)[0]))
                .toISOString()
                .split("T")[0];
              if (ele[Object.keys(ele)[0]]) {
                await dates?.[dateKey]?.stl.push(
                  String(e.option).toUpperCase()
                );
              }
            });
            await e.stdDev.map(async (ele) => {
              let dateKey = new Date(parseFloat(Object.keys(ele)[0]))
                .toISOString()
                .split("T")[0];
              if (ele[Object.keys(ele)[0]]) {
                await dates?.[dateKey]?.stdDev.push(
                  String(e.option).toUpperCase()
                );
              }
            });
            await e.isolationForest.map(async (ele) => {
              let dateKey = new Date(parseFloat(Object.keys(ele)[0]))
                .toISOString()
                .split("T")[0];
              if (ele[Object.keys(ele)[0]] === -1) {
                await dates?.[dateKey]?.isolationForest.push(
                  String(e.option).toUpperCase()
                );
              }
            });
          }
        } catch (error) {}
      });

      // console.log(dates, x);
      return dates;
    });
};
