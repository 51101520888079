import React, { useState, useEffect, useContext, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import { StockContext } from '../contexts/StockContext';

const ControlZScoreTable = React.memo(() => {
    const [volatility, setVolatility] = useState([]);
    const selectedStock = useContext(StockContext).selectedStock;
    const selectedHistoricalMeasure = useContext(StockContext).selectedHistoricalMeasure;
    const priceLast = useContext(StockContext).priceLast;
    const [historicalVolatitlity, setHistoricalVolatility] = useState([]);
    const [tableData, setTableData] = useState([]);
    const filterZScoreByExpiry = useContext(StockContext).filterZScoreByExpiry;
    const selectedExpiry = useContext(StockContext).selectedExpiry;
    const columns = [
        {
            name: <b>Code</b>,
            selector: 'callCode',
            sortable: false,
        },
        {
            name: <b>IV Z-Score</b>,
            selector: 'callZScore',
            sortable: false,
        },
        {
            name: <b>Historical Z-Score</b>,
            selector: 'callZScoreHistorical',
            sortable: false,
        },
        {
            name: <b>Style</b>,
            selector: 'optionStyle',
            sortable: true,
        },
        {
            name: <b>Expiry</b>,
            selector: 'expiry',
            sortable: false,
        },
        {
            name: <b>Strike</b>,
            selector: 'callStrike',
            sortable: false,
        },
        {
            name: <b>Code</b>,
            selector: 'putCode',
            sortable: false,
            // style: { backgroundColor: '#778899' }
        },
        {
            name: <b>IV Z-Score</b>,
            selector: 'putZScore',
            sortable: false,
            // style: { backgroundColor: '#778899' }
        },
        {
            name: <b>Historical Z-Score</b>,
            selector: 'putZScoreHistorical',
            sortable: false,
            // style: { backgroundColor: '#778899' }
        },
    ]

    const populateZScoreData = useCallback((call, put) => {
        const data = call.map(c => {
            var ans = null;
            put.forEach(p => {
                if (c.expiry === p.expiry && c.strike === p.strike) {
                    ans = { expiry: c.expiry, callOptionType: c.optionType, optionStyle: c.optionStyle, callCode: c.code, callZScore: c.zScore, callZScoreHistorical: c.zScoreHistorical, callStrike: c.strike, putOptionType: p.optionType, putCode: p.code, putZScore: p.zScore, putZScoreHistorical: p.zScoreHistorical };
                }
            })
            return ans;
        });
        setTableData(data);
    }, [])

    useEffect(() => {
        if (selectedStock !== undefined && selectedStock !== null) {
            fetch(window.location.protocol + '//api.asxsardines.com.au/volatility/' + String(selectedStock).toUpperCase()).then(res => res.json()).then(res => {
                // fetch(window.location.protocol + '//localhost:5000/volatility/' + String(selectedStock).toUpperCase()).then(res => res.json()).then(res => {
                setVolatility(res);
                setHistoricalVolatility(res?.historicalData);
            }).catch(err => console.log(err));
        }

    }, [selectedStock]);

    const createBarChartData = useCallback((code, expiry, optionType, strike, lastPrice, pVolatility, pHistoricalVolatility, style) => {
        return {
            code: code,
            expiry: expiry,
            strike: strike,
            optionType: optionType,
            optionStyle: style,
            zScore: (strike - lastPrice) / pVolatility,
            zScoreHistorical: (strike - lastPrice) / pHistoricalVolatility
        };
    }, [])



    useEffect(() => {
        var dumpCall = [];
        var dumpPut = [];
        var expirySplit = "";
        var selectedExpirySplit = undefined;
        if (selectedExpiry !== undefined && selectedExpiry !== null) {
            selectedExpirySplit = selectedExpiry.split('-');
        }
        volatility?.marginData?.forEach(v => {
            let vStrike = isNaN(parseFloat(v.strike.split('$')[1])) ? parseFloat(v.strike.replace(/,/g, '')) : parseFloat(v.strike.split('$')[1])
            if (v.contractType === "Call") {
                if (selectedExpiry !== undefined && selectedExpiry !== null) {
                    expirySplit = v.expiry.split('/');
                    if (filterZScoreByExpiry
                        && parseInt(selectedExpirySplit[0]) === parseInt(expirySplit[2])
                        && parseInt(expirySplit[1]) === parseInt(selectedExpirySplit[1])
                        && parseInt(expirySplit[0]) === parseInt(selectedExpirySplit[2])) {
                        if (parseFloat(v.volatility) !== 0) {
                            dumpCall.push(createBarChartData(v.code, v.expiry, v.contractType, vStrike, parseFloat(priceLast), parseFloat(v.volatility), parseFloat(historicalVolatitlity[selectedHistoricalMeasure]), v.style));
                        }
                        return;
                    }
                }
                if (!filterZScoreByExpiry) {
                    if (parseFloat(v.volatility) !== 0) {
                        dumpCall.push(createBarChartData(v.code, v.expiry, v.contractType, vStrike, parseFloat(priceLast), parseFloat(v.volatility), parseFloat(historicalVolatitlity[selectedHistoricalMeasure]), v.style));
                    }
                }
            }
            if (v.contractType === "Put") {
                if (selectedExpiry !== undefined && selectedExpiry !== null) {
                    expirySplit = v.expiry.split('/');
                    if (filterZScoreByExpiry
                        && parseInt(selectedExpirySplit[0]) === parseInt(expirySplit[2])
                        && parseInt(expirySplit[1]) === parseInt(selectedExpirySplit[1])
                        && parseInt(expirySplit[0]) === parseInt(selectedExpirySplit[2])) {
                        if (parseFloat(v.volatility) !== 0) {
                            dumpPut.push(createBarChartData(v.code, v.expiry, v.contractType, vStrike, parseFloat(priceLast), parseFloat(v.volatility), parseFloat(historicalVolatitlity[selectedHistoricalMeasure]), v.style));
                        }
                        return;
                    }
                }
                if (!filterZScoreByExpiry) {
                    if (parseFloat(v.volatility) !== 0) {
                        dumpPut.push(createBarChartData(v.code, v.expiry, v.contractType, vStrike, parseFloat(priceLast), parseFloat(v.volatility), parseFloat(historicalVolatitlity[selectedHistoricalMeasure]), v.style));
                    }
                }
            }
        });
        dumpCall = dumpCall.sort((a, b) => {
            if (a.zScorePositive !== undefined && b.zScorePositive !== undefined) {
                return a.zScorePositive - b.zScorePositive
            }
            else if (a.zScorePositive !== undefined && b.zScoreNegative !== undefined) {
                return a.zScorePositive - b.zScoreNegative
            }
            else if (a.zScoreNegative !== undefined && b.zScorePositive !== undefined) {
                return a.zScoreNegative - b.zScorePositive
            }
            else if (a.zScoreNegative !== undefined && b.zScoreNegative !== undefined) {
                return a.zScoreNegative - b.zScoreNegative
            }
            return null;
        });

        dumpPut = dumpPut.sort((a, b) => {
            if (a.zScorePositive !== undefined && b.zScorePositive !== undefined) {
                return a.zScorePositive - b.zScorePositive
            }
            else if (a.zScorePositive !== undefined && b.zScoreNegative !== undefined) {
                return a.zScorePositive - b.zScoreNegative
            }
            else if (a.zScoreNegative !== undefined && b.zScorePositive !== undefined) {
                return a.zScoreNegative - b.zScorePositive
            }
            else if (a.zScoreNegative !== undefined && b.zScoreNegative !== undefined) {
                return a.zScoreNegative - b.zScoreNegative
            }
            return null;
        });
        populateZScoreData(dumpCall, dumpPut);

    }, [volatility, priceLast, selectedExpiry, filterZScoreByExpiry, historicalVolatitlity, selectedHistoricalMeasure, createBarChartData, populateZScoreData]);

    return (
        <div>
            {<h2 >Z-Score Analysis ({selectedHistoricalMeasure} - {historicalVolatitlity[selectedHistoricalMeasure]})</h2>}
            <Grid container spacing={3} style={{ fontFamily: "'Oswald', sans-serif", color: 'white', marginTop: 10, backgroundColor: 'black' }}>
                <Grid item xs={6} style={{ marginTop: -9 }}>
                    Calls
                </Grid>
                <Grid item xs={6} style={{ marginTop: -9 }}>
                    Puts
                </Grid>
            </Grid>
            <DataTable
                dense={true}
                overflowY={true}
                style={{ minHeight: 340 }}
                noHeader={true} pagination={true}
                data={tableData}
                columns={columns} />
        </div>
    );
})

export default ControlZScoreTable;