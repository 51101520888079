import React from "react";

const processData = (res, state) => {
    let static_data = [];
    var dates = [];
    let processed_data = res["data"]["items"].map((k) => {
        static_data = k["exerciseGroups"].map((e) => {
            var resid = {};
            resid["expiry"] = k["date"];
            resid["call"] = e["call"]["symbol"];
            resid["callPriceExercise"] = e["call"]["priceExercise"];
            resid["callTheo"] = isNaN(Number(e["call"]["theo"])) ? "" : Number(e["call"]["theo"]).toFixed(2);
            resid["callDeemed"] = isNaN(Number(e["call"]["deemed"])) ? "" : Number(e["call"]["deemed"]).toFixed(2);
            resid["callPriceAsk"] = isNaN(Number(e["call"]["priceAsk"])) ? "" : Number(e["call"]["priceAsk"]).toFixed(2);
            resid["callPriceBid"] = isNaN(Number(e["call"]["priceBid"])) ? "" : Number(e["call"]["priceBid"]).toFixed(2);
            resid["callStyle"] = e["call"]["style"];
            resid["callOpenInterest"] = e["call"]["openInterest"];
            resid["callVolume"] = e["call"]["volume"];
            resid["callPriceLast"] = isNaN(Number(e["call"]["priceLast"])) ? "" : Number(e["call"]["priceLast"]).toFixed(2);

            resid["put"] = e["put"]["symbol"];
            resid["putPriceExercise"] = e["put"]["priceExercise"];
            resid["putTheo"] = isNaN(Number(e["put"]["theo"])) ? "" : Number(e["put"]["theo"]).toFixed(2);
            resid["putDeemed"] = isNaN(Number(e["put"]["deemed"])) ? "" : Number(e["put"]["deemed"]).toFixed(2);
            resid["putPriceAsk"] = isNaN(Number(e["put"]["priceAsk"])) ? "" : Number(e["put"]["priceAsk"]).toFixed(2);
            resid["putPriceBid"] = isNaN(Number(e["put"]["priceBid"])) ? "" : Number(e["put"]["priceBid"]).toFixed(2);
            resid["putStyle"] = e["put"]["style"];
            resid["putOpenInterest"] = e["put"]["openInterest"];
            resid["putVolume"] = e["put"]["volume"];
            resid["putPriceLast"] = isNaN(Number(e["put"]["priceLast"])) ? "" : Number(e["put"]["priceLast"]).toFixed(2);
            return resid;

        });
        dates.push(k["date"]);
        var obj = {};
        obj[k["date"]] = static_data;
        return obj;
    });

    var resid = [];
    if (res.datesAvailable.weekly.length > 0) {
        resid = res.datesAvailable.monthly.map((e, i) => {
            var ind = 0;
            var weeklies = res.datesAvailable.weekly.map(f => {
                if (e.substring(0, 7) === f.substring(0, 7)) {
                    ind+=1;
                    return <option key={ind} value={f}>{String(f)}</option>;
                }
            })
            return (
                <optgroup label={e} key={i}>
                    <option key={0} value={e}>{String(e)}</option>
                    {weeklies}
                </optgroup>
            );
        })
    }
    else {
        resid = dates.map((e, i) => {
            return <option key={i} value={e}>{String(e)}</option>;
        });
    }

    state = { ...state, priceLast: res['underlyingAsset']['priceLast'], exerciseGroups: processed_data, data: processed_data[0][dates[0]], expiryDates: resid, expiry: res.datesAvailable.monthly[0] };

    return state;
}

const processConsolidatedData = (state) => {
    var chartData = [];
    var chartDataTemp = [];
    var res = {};

    state.exerciseGroups.forEach(f => {

        f[Object.keys(f)[0]].forEach(e => {
            if (chartDataTemp.length > 0) {
                if (e['callStyle'] === 'European' && chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " callStyle"] === 'American' && (parseFloat(e.callPriceExercise) - parseFloat(chartDataTemp[chartDataTemp.length - 1]['name'])).toFixed(2) == 0.01) {
                    var data = {};
                    data['callStyle'] = e.callStyle;
                    data.name = e.callPriceExercise;
                    data.call = e.callOpenInterest;
                    data.put = e.putOpenInterest;
                    data.callAbsoluteInterest = e.callOpenInterest * 100 * e.callPriceExercise;
                    data.putAbsoluteInterest = e.callOpenInterest * 100 * e.putPriceExercise;
                    data.putCode = e.put;
                    data.callCode = e.call;
                    chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " leafEuropean"].push(data);

                    var data2 = {};
                    data2['callStyle'] = chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " callStyle"];
                    data2.name = chartDataTemp[chartDataTemp.length - 1].name;
                    data2.call = chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " call"];
                    data2.put = chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " put"];
                    data2.callAbsoluteInterest = chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " callAbsoluteInterest"];
                    data2.putAbsoluteInterest = chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " putAbsoluteInterest"];
                    data2.putCode = chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " putCode"];
                    data2.callCode = chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " callCode"];
                    chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " leafEuropean"].push(data2);

                    chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " call"] += e.callOpenInterest;
                    chartDataTemp[chartDataTemp.length - 1][String(e.expiry) + " put"] += e.putOpenInterest;
                }
                else {
                    res = {};
                    res.name = e.callPriceExercise;
                    res[String(e.expiry) + " call"] = e.callOpenInterest;
                    res[String(e.expiry) + " callStyle"] = e.callStyle;
                    res[String(e.expiry) + " put"] = e.putOpenInterest;
                    res[String(e.expiry) + " callAbsoluteInterest"] = e.callOpenInterest * 100 * e.callPriceExercise;
                    res[String(e.expiry) + " putAbsoluteInterest"] = e.callOpenInterest * 100 * e.putPriceExercise;
                    res[String(e.expiry) + " averageOI"] = ((isNaN(Number(e.callOpenInterest)) ? 0 : Number(e.callOpenInterest)) + (isNaN(Number(e.putOpenInterest)) ? 0 : Number(e.putOpenInterest))) / 2;
                    res[String(e.expiry) + " leafEuropean"] = [];
                    res[String(e.expiry) + " putCode"] = e.put;
                    res[String(e.expiry) + " callCode"] = e.call;
                    res[String(e.expiry) + " expiry"] = e.expiry;
                    chartDataTemp.push(res);
                }
            }
            else {
                res = {};
                res.name = e.callPriceExercise;
                res[String(e.expiry) + " call"] = e.callOpenInterest;
                res[String(e.expiry) + " callStyle"] = e.callStyle;
                res[String(e.expiry) + " put"] = e.putOpenInterest;
                res[String(e.expiry) + " callAbsoluteInterest"] = e.callOpenInterest * 100 * e.callPriceExercise;
                res[String(e.expiry) + " putAbsoluteInterest"] = e.callOpenInterest * 100 * e.putPriceExercise;
                res[String(e.expiry) + " averageOI"] = ((isNaN(Number(e.callOpenInterest)) ? 0 : Number(e.callOpenInterest)) + (isNaN(Number(e.putOpenInterest)) ? 0 : Number(e.putOpenInterest))) / 2;
                res[String(e.expiry) + " leafEuropean"] = [];
                res[String(e.expiry) + " putCode"] = e.put;
                res[String(e.expiry) + " callCode"] = e.call;
                res[String(e.expiry) + " expiry"] = e.expiry;
                chartDataTemp.push(res);
            }
        });
    });
    chartDataTemp.sort((a, b) => {
        if (a['name'] < b['name']) {
            return -1;
        }
        return 1;
    });
    for (var i = 0; i < chartDataTemp.length; i++) {
        var j = i + 1;
        var boolExit = false;
        while (!boolExit) {
            if (j < chartDataTemp.length) {
                if (chartDataTemp[j]['name'] === chartDataTemp[i]['name']) {
                    Object.keys(chartDataTemp[j]).forEach(f => {
                        if (!(f in ['name'])) {
                            chartDataTemp[i][f] = chartDataTemp[j][f];
                        }
                    });
                    j += 1;
                }
                else {
                    chartData.push(chartDataTemp[i]);
                    i = j - 1;
                    boolExit = true;
                }
            }
            else {
                chartData.push(chartDataTemp[i]);
                i = j;
                boolExit = true;
            }
        }
    }

    chartData.forEach(e => {
        var averageOI = 0;
        var sumOI = 0;
        var n = 0;
        Object.keys(e).forEach(f => {
            if (f.substring(f.length - 4) === 'call') {
                sumOI += e[f];
            }
            if (f.substring(f.length - 3) === 'put') {
                sumOI += e[f];
            }
            if (f.includes('averageOI')) {
                averageOI += e[f];
                n += 1;
            }
        });
        if (n !== 0) {
            e.sumOI = sumOI;
            e.n = n;
            e.averageOI = (averageOI / n).toFixed(2);
        }
        else {
            e.sumOI = 0;
            e.n = 0;
            e.averageOI = 0;
        }
    });
    var min = 1000000;
    var priceLastPivot = 0;
    chartData.forEach(e => {
        if (Math.abs(e['name'] - state.priceLast) < min) {
            min = Math.abs(e['name'] - state.priceLast);
            priceLastPivot = e['name'];
        }
    });
    state = { ...state, priceLastPivot: priceLastPivot, chartDataConsolidated: chartData };
    return state;
}

const updateChartState = (state) => {
    var res = [];
    state.data.sort((a, b) => {
        if (a.callPriceExercise < b.callPriceExercise) {
            return -1;
        }
        return 1;
    })
    var data = {};
    state.data.forEach(e => {
        if (res.length !== 0) {
            if (e['callStyle'] === 'European' && res[res.length - 1]['callStyle'] === 'American' && (parseFloat(e.callPriceExercise) - parseFloat(res[res.length - 1]['name'])).toFixed(2) == parseFloat(0.01)) {
                data = {};
                data['callStyle'] = e.callStyle;
                data.name = e.callPriceExercise;
                data.call = e.callOpenInterest;
                data.put = e.putOpenInterest;
                data.callAbsoluteInterest = e.callOpenInterest * 100 * e.callPriceExercise;
                data.putAbsoluteInterest = e.callOpenInterest * 100 * e.putPriceExercise;
                data.putCode = e.put;
                data.callCode = e.call;
                res[res.length - 1].leafEuropean.push(data);

                var data2 = {};
                data2['callStyle'] = res[res.length - 1].callStyle;
                data2.name = res[res.length - 1].name;
                data2.call = res[res.length - 1].call;
                data2.callAbsoluteInterest = res[res.length - 1].callAbsoluteInterest;
                data2.putAbsoluteInterest = res[res.length - 1].putAbsoluteInterest;
                data2.put = res[res.length - 1].put;
                data2.putCode = res[res.length - 1].putCode;
                data2.callCode = res[res.length - 1].callCode;
                res[res.length - 1].leafEuropean.push(data2);

                res[res.length - 1]['call'] += e.callOpenInterest;
                res[res.length - 1]['put'] += e.putOpenInterest;
                res[res.length - 1].averageOI = ((isNaN(Number(res[res.length - 1].callOpenInterest)) ? 0 : Number(res[res.length - 1].callOpenInterest)) + (isNaN(Number(res[res.length - 1].putOpenInterest)) ? 0 : Number(res[res.length - 1].putOpenInterest))) / 2;
            }
            else {
                data = {};
                data['callStyle'] = e.callStyle;
                data.name = e.callPriceExercise;
                data.call = e.callOpenInterest;
                data.callAbsoluteInterest = e.callOpenInterest * 100 * e.callPriceExercise;
                data.putAbsoluteInterest = e.putOpenInterest * 100 * e.putPriceExercise;
                data.put = e.putOpenInterest;
                data.averageOI = ((isNaN(Number(e.callOpenInterest)) ? 0 : Number(e.callOpenInterest)) + (isNaN(Number(e.putOpenInterest)) ? 0 : Number(e.putOpenInterest))) / 2;
                data.leafEuropean = [];
                data.putCode = e.put;
                data.callCode = e.call;
                res.push(data);
            }
        }
        else {
            data = {};
            data['callStyle'] = e.callStyle;
            data.name = e.callPriceExercise;
            data.call = e.callOpenInterest;
            data.callAbsoluteInterest = e.callOpenInterest * 100 * e.callPriceExercise;
            data.putAbsoluteInterest = e.putOpenInterest * 100 * e.putPriceExercise;
            data.put = e.putOpenInterest;
            data.averageOI = ((isNaN(Number(e.callOpenInterest)) ? 0 : Number(e.callOpenInterest)) + (isNaN(Number(e.putOpenInterest)) ? 0 : Number(e.putOpenInterest))) / 2;
            data.leafEuropean = [];
            data.putCode = e.put;
            data.callCode = e.call;
            res.push(data);
        }

    });
    var min = 1000000;
    var priceLastPivot = 0;
    res.forEach(e => {
        if (Math.abs(e['name'] - state.priceLast) < min) {
            min = Math.abs(e['name'] - state.priceLast);
            priceLastPivot = e['name'];
        }
    });
    state = { ...state, chartData: res, barPriceLastPivot: priceLastPivot };

    return state;
}

const updateStackedChartData = (state) => {
    var d = {};
    state.chartDataConsolidated.forEach(e => {
        d[e.name] = e.sumOI;
    });
    var keys = Object.keys(d);
    keys.sort((a, b) => {
        if (parseFloat(a) < parseFloat(b)) {
            return 1;
        }
        return -1;
    });
    var cumSum = {};
    var cdf = {};
    var totalSum = 0;
    var k = null;
    for (k in keys) {
        totalSum += d[keys[k]];
        cumSum[keys[k]] = totalSum;
    }
    for (k in keys) {
        cdf[keys[k]] = cumSum[keys[k]] / totalSum;
    }

    var cumChartData = [];

    var dat = state.exerciseGroups;
    var expiryCumSum = {};
    dat.forEach(f => {
        var expiryKeys = f[Object.keys(f)[0]];
        expiryKeys.sort((a, b) => {
            if (a.callPriceExercise > b.callPriceExercise) {
                return -1;
            }
            return 1;
        });
        var sumCall = 0;
        var sumCallAbsoluteInterest = 0;
        var sumPut = 0;
        var sumPutAbsoluteInterest = 0;
        var expiryCdf = {};
        expiryKeys.forEach(e => {
            sumCall += e.callOpenInterest;
            sumPut += e.putOpenInterest;
            sumPutAbsoluteInterest += e.putOpenInterest * 100 * e.callPriceExercise;
            sumCallAbsoluteInterest += e.callOpenInterest * 100 * e.callPriceExercise;
            expiryCdf[e.callPriceExercise] = { 'call': sumCall, 'put': sumPut, 'callAbsoluteInterest': sumCallAbsoluteInterest, 'putAbsoluteInterest': sumPutAbsoluteInterest };
        });
        expiryCumSum[Object.keys(f)[0]] = expiryCdf;
    });
    state = { ...state, cumChartData: cumChartData, expiryCumSum: expiryCumSum };
    state.chartDataConsolidated.forEach(e => {
        var obj = {};
        Object.keys(e).forEach(f => {
            if (f.substring(f.length - 4) === 'call') {
                obj[f] = state.expiryCumSum[f.substring(0, 10)][e.name]['call'];
            }
            else if (f.substring(f.length - 3) === 'put') {
                obj[f] = state.expiryCumSum[f.substring(0, 10)][e.name]['put'];
            }
            else if (f.substring(f.length - 20) === 'callAbsoluteInterest') {
                obj[f] = state.expiryCumSum[f.substring(0, 10)][e.name]['callAbsoluteInterest'];
            }
            else if (f.substring(f.length - 19) === 'putAbsoluteInterest') {
                obj[f] = state.expiryCumSum[f.substring(0, 10)][e.name]['putAbsoluteInterest'];
            }
            else {

                obj[f] = e[f];
            }
        });
        cumChartData.push(obj);
    });
    var expiryKeys = Object.keys(state.expiryCumSum);
    var exitT = false;
    cumChartData.forEach((e, i) => {
        var chartkeys = Object.keys(e);
        var j = i + 1;
        if (j < cumChartData.length) {
            expiryKeys.forEach(f => {
                if (!(chartkeys.includes(String(f) + " call"))) {
                    exitT = false;
                    while (!exitT) {
                        if (j < cumChartData.length) {
                            if (!Object.keys(cumChartData[j]).includes(String(f) + " call")) {
                                j += 1;
                            }
                            else {
                                e[String(f) + " call"] = cumChartData[j][String(f) + " call"];
                                e[String(f) + " callAbsoluteInterest"] = cumChartData[j][String(f) + " callAbsoluteInterest"];
                                if (!Object.keys(e).includes(String(f) + " leafEuropean")) {
                                    e[String(f) + " leafEuropean"] = cumChartData[j][String(f) + " leafEuropean"];
                                }
                                exitT = true;
                            }
                        }
                        else {
                            exitT = true;
                        }
                    }
                }
                if (!(chartkeys.includes(String(f) + " put"))) {
                    exitT = false;
                    while (!exitT) {
                        if (j < cumChartData.length) {
                            if (!Object.keys(cumChartData[j]).includes(String(f) + " put")) {
                                j += 1;
                            }
                            else {
                                e[String(f) + " put"] = cumChartData[j][String(f) + " put"];
                                e[String(f) + " putAbsoluteInterest"] = cumChartData[j][String(f) + " putAbsoluteInterest"];
                                if (!Object.keys(e).includes(String(f) + " leafEuropean")) {
                                    e[String(f) + " leafEuropean"] = cumChartData[j][String(f) + " leafEuropean"];
                                }
                                exitT = true;
                            }
                        }
                        else {
                            exitT = true;
                        }
                    }
                }
            });
        }
    });

    state = { ...state, cumChartData: cumChartData };
    return state;
}

function reducer(state = { stock: "AGL" }, action) {
    switch (action.type) {
        case "STOCK":
            return {
                ...state,
                stock: action.stock
            };
        case "LAST_PRICE":
            return {
                ...state,
                last_price: action.last_price
            };
        case "PROCESS_DATA":
            state = processData(action.req, state);
            state = updateChartState(state);
            state = processConsolidatedData(state);
            state = updateStackedChartData(state);
            return {
                ...state,
            };
        case "EXPIRY_CHANGED":
            state = { ...state, expiry: action.expiry, data: action.data };
            state = updateChartState(state);
            return {
                ...state,
            }
        default:
            return state;
    }
}

export default reducer;