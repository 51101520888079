import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingUpDown from "@mui/icons-material/TrendingDown";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Switch } from "@mui/material";

const columns = [
  {
    id: "companyName",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Stock Name
      </label>
    ),
    minWidth: 150,
  },
  {
    id: "code",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Code
      </label>
    ),
    minWidth: 40,
    format: (value) => <b>{String(value)}</b>,
  },
  {
    id: "lastPrice",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Last Price($)
      </label>
    ),
    minWidth: 50,
    align: "right",
    format: (value) =>
      "$" +
      String(
        parseFloat(value.toFixed(2)).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
      ),
  },
  {
    id: "prevChange",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Yesterday (%)
      </label>
    ),
    minWidth: 60,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "ytdChange",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        YTD(%)
      </label>
    ),
    minWidth: 50,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "consensus",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Consensus Target ($)
      </label>
    ),
    minWidth: 50,
    align: "right",
    format: (value) => <label>{value}</label>,
  },
  {
    id: "impliedVolatilityToday",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        IV Today
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => String(value.toFixed(2)) + "%",
  },
  {
    id: "impliedVolatilityPrevDay",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        IV Prev-Day
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => String(value.toFixed(2)) + "%",
  },
  {
    id: "impliedVolatilityCurrent",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Prev Change
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "impliedVolatilityMonthly",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        20 Day Change
      </label>
    ),
    minWidth: 60,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "rv50DayVolatility",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        RV Today
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => String((value * 100).toFixed(2)) + "%",
  },
  ,
  {
    id: "rv50DayPrevDayVolatility",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        RV Prev-Day
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => String((value * 100).toFixed(2)) + "%",
  },
  {
    id: "rv50DayPrevChange",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Prev Change
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "rv50DayMonthlyChange",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        20 Day Change
      </label>
    ),
    minWidth: 60,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "ivrvCurrent",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Current
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "ivrvPrevDay",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Prev Day
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "ivrvPrevWeek",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        5 Day
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "ivrvPrevMonth",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        20 Day
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "ZScore",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Z Score
      </label>
    ),
    minWidth: 40,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2))}
      </label>
    ),
  },
  {
    id: 'movingAverageToday["5-Day"]',
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        5 Day
      </label>
    ),
    minWidth: 50,
    align: "right",
  },
  {
    id: 'movingAverageToday["20-Day"]',
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        20 Day
      </label>
    ),
    minWidth: 50,
    align: "right",
  },
  {
    id: 'movingAverageToday["50-Day"]',
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        50 Day
      </label>
    ),
    minWidth: 50,
    align: "right",
  },
  {
    id: "volumePrevDay",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        Prev Day
      </label>
    ),
    minWidth: 50,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "volumePrevWeek",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        5 Day
      </label>
    ),
    minWidth: 50,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
  {
    id: "volumePrevMonth",
    label: (
      <label
        style={{
          fontFamily: "'Oswald', sans-serif",
          fontWeight: 500,
          width: 100,
        }}
      >
        20 Day
      </label>
    ),
    minWidth: 50,
    align: "right",
    format: (value) => (
      <label style={{ color: value > 0 ? "#3cb371" : "#cd5c5c" }}>
        {(value > 0 ? "+" : "") + String((value * 100).toFixed(2)) + "%"}
      </label>
    ),
  },
];

const sectors = [
  "Consumer Discretionary",
  "Consumer Staples",
  "Energy",
  "Financials",
  "Health Care",
  "Industrials",
  "Information Technology",
  "Materials",
  "Telecommunication Services",
];

export default function StockAnalytics() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [ytdData, setytdData] = React.useState(undefined);
  const [sortKey, setSortKey] = React.useState(undefined);
  const [orderBy, setOrderBy] = React.useState("asc");
  const [toggleSectors, setToggleSectors] = React.useState(false);
  const [buffer, setBuffer] = React.useState(undefined);
  const [consensusData, setConsensusData] = React.useState(undefined);

  React.useEffect(() => {
    fetch(window.location.protocol + "//api.asxsardines.com.au/stockYTD")
      .then((res) => res.json())
      .then((res) => {
        setBuffer(res);
      })
      .catch((err) => console.error(err));

    fetch(window.location.protocol + "//api.asxsardines.com.au/consensus")
      .then((res) => res.json())
      .then((res) => {
        setConsensusData(res?.[0]);
      })
      .catch((err) => console.error(err));
  }, []);

  const sortYTD = async (data) => {
    if (data === undefined) {
      return;
    }
    if (sortKey === undefined) {
      setytdData(data);
      return;
    }
    if (!toggleSectors) {
      if (sortKey !== undefined) {
        let y = {};
        let key = "0";
        y[key] = [];
        await data.map((ele, index) => {
          if (Object.keys(ele)?.length === 1) {
            key = ele.companyName;
            y[key] = [];
          } else {
            y[key].push(ele);
          }
        });
        const result = await Object.keys(y)
          .map((sector) => {
            if (Array.isArray(y[sector])) {
              return [
                sector === "0" ? void 0 : { companyName: sector },
                ...y[sector].sort((a, b) => {
                  if (
                    !isNaN(
                      parseFloat(
                        String(a[sortKey])
                          .replace(",", "")
                          .replace("%", "")
                          .replace("$", "")
                          .trim()
                      )
                    ) &&
                    !isNaN(
                      parseFloat(
                        String(b[sortKey])
                          .replace(",", "")
                          .replace("%", "")
                          .replace("$", "")
                          .trim()
                      )
                    )
                  ) {
                    if (
                      parseFloat(
                        String(a[sortKey])
                          .replace(",", "")
                          .replace("%", "")
                          .replace("$", "")
                          .trim()
                      ) <
                      parseFloat(
                        String(b[sortKey])
                          .replace(",", "")
                          .replace("%", "")
                          .replace("$", "")
                          .trim()
                      )
                    ) {
                      return orderBy === "asc" ? -1 : 1;
                    }
                    return orderBy === "asc" ? 1 : -1;
                  }
                  if (
                    String(a[sortKey]).toUpperCase() <
                    String(b[sortKey]).toUpperCase()
                  ) {
                    return orderBy === "asc" ? -1 : 1;
                  }
                  return orderBy === "asc" ? 1 : -1;
                }),
              ];
            }
          })
          .flat()
          .filter((a) => a !== undefined);
        setytdData(result);
      }
    } else {
      await data.sort((a, b) => {
        if (
          !isNaN(
            parseFloat(
              String(a[sortKey])
                .replace(",", "")
                .replace("%", "")
                .replace("$", "")
                .trim()
            )
          ) ||
          !isNaN(
            parseFloat(
              String(b[sortKey])
                .replace(",", "")
                .replace("%", "")
                .replace("$", "")
                .trim()
            )
          )
        ) {
          if (
            isNaN(
              parseFloat(
                String(a[sortKey])
                  .replace(",", "")
                  .replace("%", "")
                  .replace("$", "")
                  .trim()
              )
            )
          ) {
            return 1;
          }
          if (
            isNaN(
              parseFloat(
                String(b[sortKey])
                  .replace(",", "")
                  .replace("%", "")
                  .replace("$", "")
                  .trim()
              )
            )
          ) {
            return -1;
          }
          if (
            parseFloat(
              String(a[sortKey])
                .replace(",", "")
                .replace("%", "")
                .replace("$", "")
                .trim()
            ) ===
            parseFloat(
              String(b[sortKey])
                .replace(",", "")
                .replace("%", "")
                .replace("$", "")
                .trim()
            )
          ) {
            return 0;
          }
          if (
            parseFloat(
              String(a[sortKey])
                .replace(",", "")
                .replace("%", "")
                .replace("$", "")
                .trim()
            ) <
            parseFloat(
              String(b[sortKey])
                .replace(",", "")
                .replace("%", "")
                .replace("$", "")
                .trim()
            )
          ) {
            return orderBy === "asc" ? -1 : 1;
          } else if (
            parseFloat(
              String(a[sortKey])
                .replace(",", "")
                .replace("%", "")
                .replace("$", "")
                .trim()
            ) >
            parseFloat(
              String(b[sortKey])
                .replace(",", "")
                .replace("%", "")
                .replace("$", "")
                .trim()
            )
          ) {
            return orderBy === "asc" ? 1 : -1;
          } else {
            return 0;
          }
        }
        if (
          String(a[sortKey]).toUpperCase() < String(b[sortKey]).toUpperCase()
        ) {
          return orderBy === "asc" ? -1 : 1;
        }
        return orderBy === "asc" ? 1 : -1;
      });
      setytdData(undefined);
      setytdData(data);
    }
  };

  React.useEffect(() => {
    sortYTD(ytdData);
  }, [sortKey, orderBy]);

  React.useEffect(() => {
  }, [ytdData]);

  React.useEffect(() => {
    if (toggleSectors !== undefined && buffer !== undefined) {
      if (toggleSectors) {
        const residual = buffer.filter((e) => Object.keys(e)?.length !== 1);
        sortYTD(residual);
      } else {
        sortYTD(buffer);
      }
    }
  }, [toggleSectors, buffer]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getTrendFormatValue = (value) => {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <label
          style={{
            color:
              parseFloat(String(value).replace("%", "").replace(",", "")) > 0
                ? "#3cb371"
                : "#cd5c5c",
          }}
        >
          {value}
        </label>
        {value !== undefined ? (
          parseFloat(String(value).replace("%", "").replace(",", "")) > 0 ? (
            <TrendingUpIcon color="primary" />
          ) : (
            <TrendingUpDown color="secondary" />
          )
        ) : (
          void 0
        )}
      </div>
    );
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <Switch
        color="primary"
        onChange={() => setToggleSectors((preValue) => !preValue)}
        defaultChecked={toggleSectors}
        value={toggleSectors}
      />
      <label
        style={{
          alignContent: "left",
          marginLeft: -10,
          fontFamily: "'Oswald', sans-serif",
        }}
      >
        <b> Hide Sectors</b>
      </label>
      <TableContainer sx={{ height: 800 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={6}
                style={{ backgroundColor: "black", color: "white" }}
                sx={{ border: 2 }}
              >
                <label
                  style={{
                    fontFamily: "'Oswald', sans-serif",
                    fontWeight: 500,
                    width: 100,
                  }}
                >
                  Stock
                </label>
              </TableCell>
              <TableCell
                align="center"
                colSpan={4}
                style={{ backgroundColor: "black", color: "white" }}
                sx={{ border: 2 }}
              >
                <label
                  style={{
                    fontFamily: "'Oswald', sans-serif",
                    fontWeight: 500,
                    width: 100,
                  }}
                >
                  Implied Volatility
                </label>
              </TableCell>
              <TableCell
                align="center"
                colSpan={4}
                style={{ backgroundColor: "black", color: "white" }}
                sx={{ border: 2 }}
              >
                <label
                  style={{
                    fontFamily: "'Oswald', sans-serif",
                    fontWeight: 500,
                    width: 100,
                  }}
                >
                  Realised Volatility
                </label>
              </TableCell>
              <TableCell
                align="center"
                colSpan={4}
                style={{ backgroundColor: "black", color: "white" }}
                sx={{ border: 2 }}
              >
                <label
                  style={{
                    fontFamily: "'Oswald', sans-serif",
                    fontWeight: 500,
                    width: 100,
                  }}
                >
                  IVol/RVol
                </label>
              </TableCell>
              <TableCell
                align="center"
                colSpan={1}
                style={{ backgroundColor: "black", color: "white" }}
                sx={{ border: 2 }}
              ></TableCell>
              <TableCell
                align="center"
                colSpan={3}
                style={{ backgroundColor: "black", color: "white" }}
                sx={{ border: 2 }}
              >
                <label
                  style={{
                    fontFamily: "'Oswald', sans-serif",
                    fontWeight: 500,
                    width: 100,
                  }}
                >
                  Moving Averages
                </label>
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                style={{ backgroundColor: "black", color: "white" }}
                sx={{ border: 2 }}
              >
                <label
                  style={{
                    fontFamily: "'Oswald', sans-serif",
                    fontWeight: 500,
                    width: 100,
                  }}
                >
                  Volume Change
                </label>
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  // align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                  onClick={() => setSortKey(column.id)}
                >
                  <TableSortLabel
                    active={sortKey === column.id}
                    hideSortIcon={true}
                    direction={orderBy}
                    onClick={() => {
                      if (sortKey !== column.id) {
                        setSortKey(column.id);
                        setOrderBy("asc");
                      } else {
                        setOrderBy(orderBy === "asc" ? "desc" : "asc");
                      }
                    }}
                    sx={{
                      width: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ytdData !== undefined
              ? ytdData.map((row, index) =>
                  index > page * rowsPerPage - 1 &&
                  index < page * rowsPerPage + rowsPerPage ? (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        var value = row?.[column.id];
                        switch (column.id) {
                          case 'movingAverageToday["5-Day"]':
                            value = getTrendFormatValue(
                              row?.movingAverageToday?.["5-Day"]?.[1]
                            );
                            break;
                          case 'movingAverageToday["20-Day"]':
                            value = getTrendFormatValue(
                              row?.movingAverageToday?.["20-Day"]?.[1]
                            );
                            break;
                          case 'movingAverageToday["50-Day"]':
                            value = getTrendFormatValue(
                              row?.movingAverageToday?.["50-Day"]?.[1]
                            );
                            break;
                          case "consensus":
                            value = consensusData?.find(
                              (e) =>
                                e.SYMBOL === String(row?.code).toUpperCase()
                            )?.CONSENSUSTARGET;
                            break;
                          default:
                            break;
                        }
                        return (
                          <TableCell
                            key={column.id + String(row.code)}
                            align={column.align}
                          >
                            {value !== undefined ||
                            sectors.includes(row?.companyName) ? (
                              column.format !== undefined &&
                              value !== undefined &&
                              value !== null ? (
                                column.format(value)
                              ) : sectors.includes(value) ? (
                                <b>{value}</b>
                              ) : (
                                value
                              )
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ) : (
                    void 0
                  )
                )
              : void 0}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={ytdData?.length === undefined ? 0 : ytdData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
