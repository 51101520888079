import React, { useEffect, useState } from "react";
import { getPredictionByStock, getPredictionByStockLag } from "../API/TSFresh";
import optionList from "../data/optionList";
import { MenuItem, Select } from "@mui/material";
import {
  Brush,
  Tooltip,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import currencyFormatter from "../utils/utils";

export default function TSFresh() {
  const [stockData, setStockData] = useState(undefined);
  const [stockLagData, setStockLagData] = useState(undefined);
  const [selectedStock, setSelectedStock] = useState("XJO");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [loading, setLoading] = useState(false);
  const [brushIndex, setBrushIndex] = useState({ startIndex: 0, endIndex: 0 });

  const newOptionList = [...optionList, "snp500", "dji", "nasdaq"];

  useEffect(() => {
    setLoading(true);
    getPredictionByStock(selectedStock.toLowerCase())
      .then((res) => {
        let historicalParse = JSON.parse(res.historical);
        const hist = Object.keys(historicalParse.date).map((e, i) => {
          if (Object.keys(historicalParse.date).length - 1 === i) {
            return {
              date: new Date(historicalParse.date[e]).toLocaleDateString(),
              close: historicalParse.y[e],
              predicted: historicalParse.y[e],
            };
          }
          return {
            date: new Date(historicalParse.date[e]).toLocaleDateString(),
            close: historicalParse.y[e],
            // predicted: 0,
          };
        });
        setMax(parseFloat(Math.max(...hist.map((e) => e.close))));
        setMin(parseFloat(Math.min(...hist.map((e) => e.close))));
        const pStockData = [
          ...hist,
          ...res.predicted.date.map((e, i) => ({
            date: new Date(e).toLocaleDateString(),
            predicted: res.predicted.y[i],
            // close: 0,
          })),
        ];
        setBrushIndex({ startIndex:  pStockData.length - 100, endIndex: pStockData.length - 1 });
        setStockData(
          pStockData
          //     {
          //     historical: hist,
          //     predicted: res.predicted.date.map((e, i) => ({
          //       date: new Date(e).toLocaleDateString(),
          //       close: res.predicted.y[i],
          //     })),
          //   }
        );
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });

    getPredictionByStockLag(selectedStock.toLowerCase())
      .then((res) => {
        let historicalParse = JSON.parse(res.historical);
        const hist = Object.keys(historicalParse.date).map((e, i) => {
          if (Object.keys(historicalParse.date).length - 1 === i) {
            return {
              date: new Date(historicalParse.date[e]).toLocaleDateString(),
              close: historicalParse.y[e],
              predicted: historicalParse.y[e],
            };
          }
          return {
            date: new Date(historicalParse.date[e]).toLocaleDateString(),
            close: historicalParse.y[e],
            // predicted: 0,
          };
        });
        setStockLagData(
          [
            ...hist,
            ...res.predicted.date.map((e, i) => ({
              date: new Date(e).toLocaleDateString(),
              predicted: res.predicted.y[i],
              // close: 0,
            })),
          ]
          //     {
          //     historical: hist,
          //     predicted: res.predicted.date.map((e, i) => ({
          //       date: new Date(e).toLocaleDateString(),
          //       close: res.predicted.y[i],
          //     })),
          //   }
        );
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [selectedStock]);

  return (
    <>
      <Select
        value={selectedStock}
        size="small"
        style={{ width: 200, marginTop: 10 }}
        onChange={(e) => setSelectedStock(e.target.value)}
      >
        {newOptionList.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
      {stockLagData && stockData && !loading && (
        <>
          <ResponsiveContainer height={600}>
            <ComposedChart
              data={stockData}
              margin={{
                top: 10,
                right: 100,
                left: 50,
                bottom: 0,
              }}
              syncId="1"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                angle={-60}
                type="number"
                tickFormatter={(value) => currencyFormatter(value)}
                domain={[`dataMin - ${min / 100}`, `dataMax + ${max / 100}`]}
              />
              <Tooltip
                formatter={(value) => currencyFormatter(value.toFixed(2))}
              />
              <Legend
                payload={[
                  {
                    id: "close",
                    type: "square",
                    value: "Historical",
                    color: "#7393B3",
                  },
                  {
                    id: "predicted",
                    type: "square",
                    value: "Predicted",
                    color: "#93b373",
                  },
                ]}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="close"
                stroke="#004488"
                // fill="#7393B3"
                dot={false}
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="predicted"
                stroke="#228B22"
                // fill="#93b373"
                activeDot={{ r: 8 }}
                dot={false}
              />
              <Brush
                dataKey="date"
                startIndex={brushIndex?.startIndex}
                endIndex={brushIndex?.endIndex}
                height={0}
                onChange={(newIndex) => {
                  console.log("changeno", newIndex);
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={600}>
            <ComposedChart
              data={stockData}
              margin={{
                top: 10,
                right: 100,
                left: 50,
                bottom: 0,
              }}
              syncId="1"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                angle={-60}
                type="number"
                tickFormatter={(value) => currencyFormatter(value)}
                domain={[`dataMin - ${min / 100}`, `dataMax + ${max / 100}`]}
              />
              <Tooltip
                formatter={(value) => currencyFormatter(value.toFixed(2))}
              />
              <Legend
                payload={[
                  {
                    id: "close",
                    type: "square",
                    value: "Historical",
                    color: "#7393B3",
                  },
                  {
                    id: "predicted",
                    type: "square",
                    value: "Predicted",
                    color: "#93b373",
                  },
                ]}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="close"
                stroke="#004488"
                // fill="#7393B3"
                dot={false}
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="predicted"
                stroke="#228B22"
                // fill="#93b373"
                activeDot={{ r: 8 }}
                dot={false}
              />
              <Brush
                dataKey="date"
                startIndex={brushIndex?.startIndex}
                endIndex={brushIndex?.endIndex}
                onChange={(newIndex) => {
                  setBrushIndex(newIndex);
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}

      {loading && <h4>Loading...</h4>}
    </>
  );
}
