import { React, useState, useEffect, useContext, useCallback } from 'react';
import { StockContext } from '../contexts/StockContext';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';

export default function ControlZScoreChart(props) {
    const [volatility, setVolatility] = useState([]);
    const historicalMeasure = props.historicalMeasure;
    const selectedStock = useContext(StockContext).selectedStock;
    const selectedHistoricalMeasure = useContext(StockContext).selectedHistoricalMeasure;
    const priceLast = useContext(StockContext).priceLast;
    const [historicalVolatitlity, setHistoricalVolatility] = useState([]);
    const filterZScoreByExpiry = useContext(StockContext).filterZScoreByExpiry;
    const selectedExpiry = useContext(StockContext).selectedExpiry;
    const [chartData, setChartData] = useState([]);
    useEffect(()=>{
        if (selectedStock !== undefined && selectedStock !== null) {
            fetch(window.location.protocol + '//api.asxsardines.com.au/volatility/' + String(selectedStock).toUpperCase()).then(res => res.json()).then(res => {
                // fetch(window.location.protocol + '//localhost:5000/volatility/' + String(selectedStock).toUpperCase()).then(res => res.json()).then(res => {
                setVolatility(res);
                setHistoricalVolatility(res?.historicalData);
            }).catch(err => console.log(err));
        }
    },[selectedStock]);
    const CustomToolTip = useCallback(
        ({ active, payload, label }) => {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc', alignContent: 'center' }}>
                    <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}>
                        <b>{label} {props?.style}</b><br />
                        <b>{payload !== undefined && payload?.length > 0 ? payload[0]?.payload.optionStyle === "A" ? "American" : "European" : void (0)}</b><br />
                        <b>{payload !== undefined && payload?.length > 0 ? payload[0]?.payload.expiry : void (0)}</b>
                    </label><br />
                    <label style={{ color: "#FBB03B", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Strike Price: $</b>{` ${payload !== undefined && payload?.length > 0 ? payload[0]?.payload.strike : void (0)}`}</label><br />
                    <label style={{ color: "#8f8f8f", fontSize: 12, fontFamily: "'Oswald', sans-serif" }} >
                        <b>Z-Score:</b>
                    </label>
                    <label style={{ color: "#a9a9a9", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}>
                        <b>  {payload !== undefined && payload?.length > 0 ? (payload[0]?.payload.zScorePositive !== undefined ? payload[0]?.payload.zScorePositive : payload[0]?.payload.zScoreNegative) : void (0)}</b>
                    </label><br />
                </div>
            );
        }
    )

    const createBarChartData = useCallback((code, expiry, optionType, strike, lastPrice, volatility, style) => {
        if ((strike - lastPrice) / volatility > 0) {
            return {
                code: code,
                expiry: expiry,
                strike: strike,
                optionType: optionType,
                optionStyle: style,
                zScorePositive: (strike - lastPrice) / volatility
            };
        }
        return {
            code: code,
            expiry: expiry,
            strike: strike,
            optionType: optionType,
            optionStyle: style,
            zScoreNegative: (strike - lastPrice) / volatility
        };
    })

    useEffect(() => {
        var dump = [];
        var selectedExpirySplit = undefined;
        if (selectedExpiry !== undefined && selectedExpiry !== null) {
            selectedExpirySplit = selectedExpiry.split('-');
        }
        volatility?.marginData?.forEach(v => {
            //switch (v.style) {
            //    case "A":
            let vStrike = isNaN(parseFloat(v.strike.split('$')[1]))?parseFloat(v.strike.replace(/,/g, '')):parseFloat(v.strike.split('$')[1])
            if (v.contractType === props.style) {
                if (selectedExpiry !== undefined && selectedExpiry !== null) {
                    var expirySplit = v.expiry.split('/');
                    if (filterZScoreByExpiry
                        && parseInt(selectedExpirySplit[0]) === parseInt(expirySplit[2])
                        && parseInt(expirySplit[1]) === parseInt(selectedExpirySplit[1])
                        && parseInt(expirySplit[0]) === parseInt(selectedExpirySplit[2])) {
                        if (historicalMeasure) {
                            dump.push(createBarChartData(v.code, v.expiry, v.contractType, vStrike, parseFloat(priceLast), parseFloat(historicalVolatitlity[selectedHistoricalMeasure]), v.style));
                        }
                        else {
                            if (parseFloat(v.volatility) !== 0) {
                                dump.push(createBarChartData(v.code, v.expiry, v.contractType, vStrike, parseFloat(priceLast), parseFloat(v.volatility), v.style));
                            }
                        }
                        return;
                    }
                }
                if (!filterZScoreByExpiry) {
                    if (historicalMeasure) {
                        dump.push(createBarChartData(v.code, v.expiry, v.contractType, vStrike, parseFloat(priceLast), parseFloat(historicalVolatitlity[selectedHistoricalMeasure]), v.style));
                    }
                    else {
                        if (parseFloat(v.volatility) !== 0) {
                            dump.push(createBarChartData(v.code, v.expiry, v.contractType, vStrike, parseFloat(priceLast), parseFloat(v.volatility), v.style));
                        }
                    }
                }
            }
            //        break;
            //    default:
            //        break;
            //}
        });
        dump = dump.sort((a, b) => {
            
            if (b.strike !== undefined && a.strike !== undefined) {
                if(a.strike === b.strike)
                {
                    if (a.zScorePositive !== undefined && b.zScorePositive !== undefined) {
                        return a.zScorePositive - b.zScorePositive
                    }
                    else if (a.zScorePositive !== undefined && b.zScoreNegative !== undefined) {
                        return a.zScorePositive - b.zScoreNegative
                    }
                    else if (a.zScoreNegative !== undefined && b.zScorePositive !== undefined) {
                        return a.zScoreNegative - b.zScorePositive
                    }
                    else if (a.zScoreNegative !== undefined && b.zScoreNegative !== undefined) {
                        return a.zScoreNegative - b.zScoreNegative
                    }
                }
                return a.strike - b.strike
            }
            //else if (a.zScorePositive !== undefined && b.zScoreNegative !== undefined) {
            //    return a.zScorePositive - b.zScoreNegative
            //}
            //else if (a.zScoreNegative !== undefined && b.zScorePositive !== undefined) {
            //    return a.zScoreNegative - b.zScorePositive
            //}
            //else if (a.zScoreNegative !== undefined && b.zScoreNegative !== undefined) {
            //    return a.zScoreNegative - b.zScoreNegative
            //}
        });
        setChartData(dump);
    }, [volatility, priceLast, selectedExpiry, filterZScoreByExpiry, historicalVolatitlity, selectedHistoricalMeasure]);

    

    return (
        <div>
            {!historicalMeasure ? <h2 >Implied {props.style}s</h2> : <h2 >Historical {props.style}s ({selectedHistoricalMeasure} - {historicalVolatitlity[selectedHistoricalMeasure]})</h2>}
            <ResponsiveContainer width="100%" height={400} >
                <BarChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="code" />
                    <YAxis />
                    <Tooltip content={CustomToolTip} />
                    <Legend />
                    <ReferenceLine y={0} stroke="#000" />
                    <Bar stackId="a" dataKey="zScoreNegative" fill="#ff0000" />
                    <Bar stackId="a" dataKey="zScorePositive" fill="#82ca9d" />
                    {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}