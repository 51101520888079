import React, { useState } from "react";

import "./App.css";
import "./Fonts.css";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
// import Button from "@material-ui/core/Button";
import AnalysisToolHomeScreen from "./screens/AnalysisToolHomeScreen.js";
import ControlNotifications from "./controls/ControlNotifications.js";
import ZScoreComponent from "./components/ZScoreComponent";
import ControlDonutChart from "./controls/ControlDonutChart.js";
import StockAnalytics from "./components/StockAnalytics";
import PutCallRatio from "./components/PutCallRatio";
import GARCHSeries from "./components/GARCHSeries";
import ControlNavigation from "./controls/ControlNavigation";
import HistoricalVolatility from "./components/HistoricalVolatility";
import HistoricalAnalytics from "./components/HistoricalAnalytics";
import NetLiquidityModel from "./components/NetLiquidityModel";
import ASXAuthenticator from "./Auth/ASXAuthenticator";
import TSFresh from "./screens/TSFresh";

const RenderSwitch = (activeRoute) => {
  switch (activeRoute) {
    case "StockAnalytics":
      return <StockAnalytics />;
    case "Historical Analytics":
      return <HistoricalAnalytics />;
    case "Notifications":
      return <ControlNotifications />;
    case "Sectors":
      return <ControlDonutChart />;
    case "Z Scores":
      return <ZScoreComponent />;
    case "Put-Call Ratio":
      return <PutCallRatio />;
    case "Volatility Models":
      return <GARCHSeries />;
    case "Historical Volatility Outliers":
      return <HistoricalVolatility />;
    case "Net Liquidity":
      return <NetLiquidityModel />;
    case "TS Fresh":
      return <TSFresh />;
    default:
      return <AnalysisToolHomeScreen />;
  }
};

function App() {
  const [activeRoute, setActiveRoute] = useState("Home");
  const [isOpen, setIsOpen] = useState(false);

  // const onNotificationsClick = () => {
  //   setActiveRoute("Notifications");
  //   //console.log('Hello');
  //   //fetch('http://34.223.28.113:5000/refreshData/').then(() => {
  //   //  window.location.reload()
  //   //});
  // }
  // const onSectorClick = () => {
  //   setActiveRoute("Sectors");
  // }
  // const onZScoreClick = () => {
  //   setActiveRoute("ZScores");
  // }
  // const onHomeClick = () => {
  //   setActiveRoute("");
  // }
  // const onStockAnalyticsClick = () => {
  //   setActiveRoute("StockAnalytics");
  // }
  // const onPutCallRatioClick = () => {
  //   setActiveRoute("PutCallRatio");
  // }
  // const onVolatilityModelClick = () => {
  //   setActiveRoute("VolatilityModel");
  // }

  return (
    <ASXAuthenticator>
      <div className="App">
        <AppBar position="static">
          <Toolbar variant="dense" style={{ backgroundColor: "#2f3131" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setIsOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h5"
              align="left"
              style={{
                marginTop: "-2px",
                flexGrow: 1,
                fontFamily: "'Oswald', cursive",
              }}
            >
              {activeRoute}
            </Typography>
            {/* <span style={{ marginTop: '10px', marginRight: '20px' }}>
              {this.state.analytics===undefined?null:this.state.analytics}
            </span> */}
            {/* <Button variant="contained" align="right"
            onClick={onNotificationsClick} style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, width: 100 }}
          >{"Notifications"}</Button>
          <Button variant="contained" align="right"
            onClick={onSectorClick} style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, marginLeft: 20, width: 100 }}
          >{"Sectors"}</Button>
          {<Button variant="contained" align="right"
            onClick={onZScoreClick} style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, marginLeft: 20, width: 100 }}
          >{"Z Scores"}</Button>}
          <Button variant="contained" align="right"
            onClick={onStockAnalyticsClick} style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, marginLeft: 20, width: 100 }}
          >{"StockAnalytics"}</Button>
          <Button variant="contained" align="right"
            onClick={onPutCallRatioClick} style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, marginLeft: 20, width: 150 }}
          >{"Put-Call Ratio"}</Button>
          <Button variant="contained" align="right"
            onClick={onVolatilityModelClick} style={{ fontFamily: "'Oswald', sans-serif", fontWeight: 500, marginLeft: 20, width: 150 }}
          >{"Volatility Models"}</Button> */}
          </Toolbar>
        </AppBar>
        {RenderSwitch(activeRoute)}
        <ControlNavigation
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setActiveRoute={setActiveRoute}
          activeRoute={activeRoute}
        />
      </div>
    </ASXAuthenticator>
  );
}

export default App;
