import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import ControlZScoreChart from "../controls/ControlZScoreChart"
import { StockContext } from "../contexts/StockContext"
import { connect, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import optionList from '../data/optionList';

function ZScoreComponent(props) {
    const [filterZScoreByExpiry, setFilterZScoreByExpiry] = useState(false);
    const dispatch = useDispatch();
    const historicalMeasureOptions = ['9-Day','14-Day','20-Day','50-Day','100-Day'].map((e, i) => {
        return <option key={i} value={e}  >{String(e)}</option>;
    });
    const [selectedHistoricalMeasure, setSelectedHistoricalMeasure] = useState('9-Day');
    const [selectedStock, setSelectedStock] = useState(String(optionList[0]).toUpperCase());
    const options = props?.sectorOptions===undefined?optionList.map((e, i) => {
        return <option key={i} value={e}  >{String(e)}</option>;
    }):props?.sectorOptions;

    useEffect(() => {
        dispatch({ type: 'STOCK', stock: String(optionList[0]).toUpperCase() });
        window.document.title = String(optionList[0]).toUpperCase() + " OI Analysis";
        fetch(window.location.protocol + '//api.asxsardines.com.au/requestData/' + String(optionList[0]).toUpperCase(), {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            credentials: 'omit'
        }).then(res => res.json()).then(res => {
            dispatch({ type: 'PROCESS_DATA', req: res });
        });
    }, []);

    const onOptionChanged = (e) => {
        dispatch({ type: 'STOCK', stock: e.target.value });
        setSelectedStock(e.target.value);
        window.document.title = String(e.target.value) + " OI Analysis";
        fetch(window.location.protocol + '//api.asxsardines.com.au/requestData/' + String(e.target.value).toUpperCase(), {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            credentials: 'omit'
        }).then(res => res.json()).then(res => {
            dispatch({ type: 'PROCESS_DATA', req: res });
        });
    }
    const onExpiryChanged = (e) => {
        props.exerciseGroups.forEach(k => {
            if (e.target.value === Object.keys(k)[0]) {
                dispatch({ type: 'EXPIRY_CHANGED', expiry: Object.keys(k)[0], data: k[Object.keys(k)[0]] })
            }
        });
    }

    return (
        <StockContext.Provider value={{ selectedStock: selectedStock, priceLast: props.priceLast, selectedExpiry: props.expiry, filterZScoreByExpiry: filterZScoreByExpiry, selectedHistoricalMeasure: selectedHistoricalMeasure }}>
            <div>
                <div align="left" >
                    <Select
                        native
                        onChange={onOptionChanged}
                        style={{ width: '300px', margin: 10, fontFamily: "'Oswald', sans-serif" }}
                    >
                        {options}
                    </Select>
                    <Select
                        native
                        onChange={onExpiryChanged}
                        style={{ margin: 10, width: '150px', fontFamily: "'Oswald', sans-serif" }}
                    >
                        {props.expiryDates}
                    </Select>
                    <Select
                        native
                        onChange={(e)=>setSelectedHistoricalMeasure(e.target.value)}
                        style={{ margin: 10, width: '150px', fontFamily: "'Oswald', sans-serif" }}
                    >
                        {historicalMeasureOptions}
                    </Select>
                    <span style={{ marginLeft: 10 }}><Switch color="primary" value={filterZScoreByExpiry} onClick={() => setFilterZScoreByExpiry(!filterZScoreByExpiry)} /> <label style={{ alignContent: 'left', marginLeft: -10, fontFamily: "'Oswald', sans-serif" }}><b>Filter by Expiry (Z-Score)</b></label></span>
                    <label style={{ alignContent: 'center',marginLeft: 20, fontFamily: "'Oswald', sans-serif" }}><b>Last Price: ${props?.priceLast}</b></label>
                </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                <div style={{ color: "#3d3d3d", fontFamily: "'Oswald', sans-serif" }}>
                    <ControlZScoreChart style="Call" />
                    <ControlZScoreChart style="Put" />
                </div>
                <div style={{ color: "#3d3d3d", fontFamily: "'Oswald', sans-serif" }}>
                    <ControlZScoreChart style="Call" historicalMeasure={true}/>
                    <ControlZScoreChart style="Put" historicalMeasure={true}/>
                </div>
            </div>
        </StockContext.Provider>
    )
}

function mapStateToProps(state) {
    if (state.data !== undefined) {
        state.filterOIData = state.data.filter(e => (e.putOpenInterest > 0 && e.putOpenInterest !== null) || (e.callOpenInterest > 0 && e.callOpenInterest !== null));
        state.filterVolumeData = state.data.filter(e => (e.putVolume > 0 && e.putVolume !== null) || (e.callVolume > 0 && e.callVolume !== null));
        state.filteredData = state.data.filter(e => (e.putVolume > 0 && e.putVolume !== null && e.putOpenInterest > 0 && e.putOpenInterest !== null) || (e.callVolume > 0 && e.callVolume !== null && e.callOpenInterest > 0 && e.callOpenInterest !== null));
    }
    return { optionSelected: state.stock, chartData: state.chartData, exerciseGroups: state.exerciseGroups, priceLast: state.priceLast, priceLastPivot: state.priceLastPivot, data: state.data, expiryDates: state.expiryDates, chartDataConsolidated: state.chartDataConsolidated, expiryCumSum: state.expiryCumSum, cumChartData: state.cumChartData, expiry: state.expiry, barPriceLastPivot: state.barPriceLastPivot, filterOIData: state.filterOIData, filterVolumeData: state.filterVolumeData, filteredData: state.filteredData };
};
export default connect(mapStateToProps)(ZScoreComponent);