import React, { Component } from 'react';
import { ComposedChart, Bar, Brush, YAxis, XAxis, CartesianGrid, ReferenceLine, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@material-ui/core';
import currencyFormatter from '../utils/utils';

const xAxisTickFormatter = (price_tick) => {
    return "$ " + String(price_tick)
}

export default class ControlOptionOLHist extends Component {
    constructor(props) {
        super(props);
        this.renderBarChart = this.renderBarChart.bind(this);
        this.CustomTooltip = this.CustomTooltip.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            selectedState: 'default',
        }
    }
    handleChange = (event) => {
        this.setState({ selectedState: event.target.value });
    };
    CustomTooltip = ({ active, payload, label }) => {
        try {
            if (active && payload !== undefined && payload.length > 0) {
                var temp = [];

                if (payload[0].payload.leafEuropean !== undefined) {
                    if (payload[0].payload.leafEuropean.length > 0) {
                        temp.push(
                            <div key={1}>
                                <label style={{ color: "#FBB03B", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Strike Price: $</b>{` ${payload[0].payload.name}`}</label>
                            </div>
                        );

                        if (this.state.selectedState === 'default' || this.state.selectedState === 'call') {
                            temp.push(
                                <div key={2}>
                                    <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Total Call OI:</b>{`  ${parseInt(payload[0].payload.call).toLocaleString()}`}</label>
                                </div>)
                        }
                        if (this.state.selectedState === 'default' || this.state.selectedState === 'put') {
                            temp.push(
                                <div key={3}>
                                    <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Total Put OI:</b>{`  ${parseInt(payload[0].payload.put).toLocaleString()}`}</label></div>)
                        }
                        temp.push(
                            <div key={4}>
                                <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>American:</b></label>
                            </div>
                        )

                        if (this.state.selectedState === 'default' || this.state.selectedState === 'call') {
                            temp.push(
                                <div key={5}><label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>{payload[0].payload.leafEuropean[1].callCode}</b>{` Call OI: ${parseInt(payload[0].payload.leafEuropean[1].call).toLocaleString()}`}</label></div>);
                        }
                        if (this.state.selectedState === 'default' || this.state.selectedState === 'put') {
                            temp.push(
                                <div key={6}><label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>{payload[0].payload.leafEuropean[1].putCode}</b>{` Put OI: ${parseInt(payload[0].payload.leafEuropean[1].put).toLocaleString()}`}</label></div>)
                        }

                        temp.push(
                            <div key={7}>
                                <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>European:</b></label>
                            </div>
                        )
                        if (this.state.selectedState === 'default' || this.state.selectedState === 'call') {
                            temp.push(
                                <div key={8}>
                                    <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>{payload[0].payload.leafEuropean[0].callCode}</b>{` Call OI: ${parseInt(payload[0].payload.leafEuropean[0].call).toLocaleString()}`}</label>
                                </div>
                            )
                        }
                        if (this.state.selectedState === 'default' || this.state.selectedState === 'put') {
                            temp.push(
                                <div key={9}>
                                    <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>{payload[0].payload.leafEuropean[0].putCode}</b>{` Put OI: ${parseInt(payload[0].payload.leafEuropean[0].put).toLocaleString()}`}</label>
                                </div>
                            )
                        }
                        return (
                            <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc', alignContent: 'center' }}>
                                {temp}
                            </div>
                        );
                    }
                    else {
                        temp.push(
                        <div key={1}>
                        <label style={{ color: "#FBB03B", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Strike Price: $</b>{` ${payload[0].payload.name}`}</label>
                        </div>
                        )
                        if (this.state.selectedState === 'default' || this.state.selectedState === 'call') {
                            temp.push(
                            <div key={2}>
                            <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>{payload[0].payload.callCode}</b>{` Call OI: ${parseInt(payload[0].value).toLocaleString()}`}</label>
                            </div>
                            )
                        }

                        if (this.state.selectedState === 'default' || this.state.selectedState === 'put') {
                            temp.push(
                            <div key={3}>
                            <label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>{payload[1].payload.putCode}</b>{` Put OI: ${parseInt(payload[1].value).toLocaleString()}`}</label>
                            </div>
                            )
                        }
                        // if(this.state.selectedState === 'default')
                        // {
                        //     temp.push(<label style={{ color: "#808080", fontSize: 12, fontFamily: "'Oswald', sans-serif" }}><b>Average Open Interest:</b>{` ${payload[1].payload.averageOI}`}</label>)
                        // }
                        // temp.push(<br/>)
                        return (
                            <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc', alignContent: 'center' }}>
                                {temp}
                            </div>
                        );
                    }
                }
            }

            return null;
        }
        catch (e) {
            return '';
        }
    };

    renderBarChart = () => {
        return (
            ''
        );
    }
    render() {
        if (this.props.data !== undefined && this.props.priceLast !== undefined) {
            return (
                <div style={{ height: '300px' }}>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="position" name="position" value={this.state.selectedState} onChange={this.handleChange} row>
                            <FormControlLabel
                                value="default"
                                control={<Radio color="primary" />}
                                label={<span style={{ fontFamily: "'Oswald', sans-serif" }}>Default</span>}
                                labelPlacement="bottom"
                            />
                            <FormControlLabel
                                value="call"
                                control={<Radio color="primary" />}
                                label={<span style={{ fontFamily: "'Oswald', sans-serif" }}>Call</span>}
                                labelPlacement="bottom"
                            />
                            <FormControlLabel
                                value="put"
                                control={<Radio color="primary" />}
                                label={<span style={{ fontFamily: "'Oswald', sans-serif" }}>Put</span>}
                                labelPlacement="bottom"
                            />
                        </RadioGroup>
                    </FormControl>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart style={{ flex: 1 }} data={this.props.data}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis angle={-60} textAnchor="end" tickFormatter={(item)=>this.props.axisSwitch?currencyFormatter(item):item.toLocaleString()}>
                                <Label fontFamily="'Oswald', sans-serif" angle={-90} value={this.props.axisSwitch ? "Market Value" : "Open Interest"} position='insideLeft' style={{ textAnchor: 'middle' }} />
                            </YAxis>
                            <Tooltip content={<this.CustomTooltip />} />
                            <Legend />
                            {this.state.selectedState === 'default' || this.state.selectedState === 'call' ?
                                (this.props.axisSwitch === false ? <Bar stackId="c" dataKey="call" fill="#76EE00" /> : <Bar stackId="a" dataKey="callAbsoluteInterest" fill="#76EE00" />)
                                : []}
                            {this.state.selectedState === 'default' || this.state.selectedState === 'put' ?
                                (this.props.axisSwitch === false ? <Bar stackId="d" dataKey="put" fill="#e3242b" /> : <Bar stackId="b" dataKey="putAbsoluteInterest" fill="#e3242b" />)
                                : []}
                            <ReferenceLine x={this.props.priceLastPivot} stroke="black" label={"Last Price ($ " + String(this.props.priceLast.toFixed(2)) + ")"} strokeDasharray="3 3" />
                            {/* <Line type="monotone" dataKey="averageOI" fill="#76EE00" /> */}
                            <Brush tickFormatter={xAxisTickFormatter} dataKey="name" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            );
        }
        return <div></div>;
    }
}