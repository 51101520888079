import React, { useEffect, useState } from "react";
import Switch from '@material-ui/core/Switch';
import optionList from '../data/optionList';
import Select from '@material-ui/core/Select';
import { add, format, differenceInCalendarDays, isFuture } from "date-fns";
import CircularProgress from '@mui/material/CircularProgress';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import currencyFormatter from "../utils/utils";

const standardDeviation = (arr, usePopulation = false) => {
    const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
    return Math.sqrt(
        arr
            .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
            .reduce((acc, val) => acc + val, 0) /
        (arr.length - (usePopulation ? 0 : 1))
    );
};

export default function GARCHSeries() {
    const [timeSeries, setTimeSeries] = useState(undefined);
    const [conditionalVolatility, setConditionalVolatility] = useState(undefined);
    const [eConditionalVolatility, setEConditionalVolatility] = useState(undefined);
    const [returns, setReturns] = useState(undefined);
    const [prices, setPrices] = useState(undefined);
    const [selectedStock, setSelectedStock] = useState("XJO");
    const [dates, setDates] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [normalizeView, setNormalizeView] = useState(false);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    const options = optionList.map((e, i) => {
        return <option key={i} value={e}  >{String(e)}</option>;
    });

    const constructTimeSeries = async () => {
        setIsLoading(true);
        await fetch(`https://sq1c9uk66i.execute-api.us-west-2.amazonaws.com/default/GARCH?symbol=${selectedStock}`, {
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": null,
            "method": "GET",
            "mode": "cors",
            "credentials": "omit"
        }).then(res => res.json()).then(async res => {
            setConditionalVolatility(res.conditional_volatility);
            setEConditionalVolatility(res.e_conditional_volatility);
            setReturns(res.returns);
            setEndIndex(res.returns.length-1);
            setPrices(res.prices.slice(1));
            setDates(res.dates.map(e => format(new Date(e).getTime(), "dd/MMM/yy")).slice(1));
        }).catch(console.error);
        setIsLoading(false);
    }

    useEffect(() => {
        constructTimeSeries();
    }, [selectedStock]);

    useEffect(() => {
        if (returns === undefined || dates === undefined || conditionalVolatility === undefined) {
            return;
        }
        let avg = conditionalVolatility.reduce((sum, e) => sum + e, 0) / conditionalVolatility.length;
        let std = standardDeviation(conditionalVolatility);

        let eAvg = eConditionalVolatility.reduce((sum, e) => sum + e, 0) / eConditionalVolatility.length;
        let eStd = standardDeviation(eConditionalVolatility);
        setTimeSeries(dates.map((e, i) => {
            return {
                ts: e
                , conditional_volatility: normalizeView === true ? (conditionalVolatility[i] - avg) / std : conditionalVolatility[i]
                , e_conditional_volatility: normalizeView === true ? (eConditionalVolatility[i] - eAvg) / eStd : eConditionalVolatility[i]
                , prices: prices[i]
                , returns: returns[i]
            }
        }));
        setMinPrice(Math.min(...prices) * 0.98);
        setMaxPrice(Math.max(...prices) * 1.02);
    }, [returns, dates, conditionalVolatility, normalizeView]);

    return (
        <div>
            <Select
                native
                onChange={(e) => {
                    setSelectedStock(e.target.value);
                }}
                value={selectedStock}
                style={{ width: '300px', margin: 10, fontFamily: "'Oswald', sans-serif" }}
            >
                {options}
            </Select>
            {
                timeSeries !== undefined && selectedStock !== undefined && isLoading !== true ?
                    <div>
                        <Switch color="primary" onChange={() => setNormalizeView(preValue => !preValue)} value={normalizeView} /><label style={{ alignContent: 'left', marginLeft: -10, fontFamily: "'Oswald', sans-serif" }}><b>  Normalize On/Off</b></label>
                        <ResponsiveContainer width='100%' height={450}>
                            <LineChart
                                width={500}
                                height={300}
                                data={timeSeries.slice(startIndex, endIndex)}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="ts" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line isAnimationActive={false} type="monotone" dataKey="conditional_volatility" stroke="#8884d8" activeDot={{ r: 8 }} dot={false} />
                                <Line isAnimationActive={false} type="monotone" dataKey="e_conditional_volatility" stroke="#38b4d8" activeDot={{ r: 8 }} dot={false} />
                                {/* <Line type="monotone" dataKey="prices" stroke="#A6b4d8" activeDot={{ r: 8 }} dot={false} /> */}
                                <Line isAnimationActive={false} type="monotone" dataKey="returns" stroke="#cd5c5c" activeDot={{ r: 8 }} dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                        <ResponsiveContainer width='100%' height={450}>
                            <LineChart
                                width={500}
                                height={300}
                                data={timeSeries}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="ts" />
                                <YAxis angle={-60} domain={[minPrice, maxPrice]} tickFormatter={(value) => currencyFormatter(value)} />
                                <Tooltip formatter={(value) => currencyFormatter(value)} />
                                <Legend />
                                <Line type="monotone" dataKey="prices" stroke="#8884d8" activeDot={{ r: 8 }} dot={false} />
                                <Brush onChange={(e) => {
                                    console.log("e", e,timeSeries)
                                    setStartIndex(e.startIndex);
                                    setEndIndex(e.endIndex);
                                }} dataKey="ts" />
                            </LineChart>
                        </ResponsiveContainer>
                        {/* <button onClick={() => { setNormalizeView(preValue => !preValue) }}>Normalize On/Off</button> */}
                    </div>

                    :
                    <div style={{ width: '100%', backgroundColor: 'rgba(0,0,0,0.6)', height: 450, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress />
                    </div>
            }
        </div>
    );
}
